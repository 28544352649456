import React, { Fragment, cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  Filter,
  TextInput,
  useTranslate
} from 'react-admin';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import {Typography, Chip, Box} from '@material-ui/core';
import { checkDateExpired, getClientPortalOrderStatus, getFullDateTimeFormat } from "helpers/utils";
import OrderDetailsDrawer from "./OrderDetailDrawer";
import { getLocale } from "layout/MyAppBar";

const OrderListActions = (props) => {
  const translate = useTranslate();
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} label={translate('ra.action.add_order')} />
      {
        props.isDrawerOpen ? 
          <OrderDetailsDrawer 
            isOpen={props.isDrawerOpen} 
            toggleDrawer={props.toggleDrawer} 
            record={props.record}
          />
        :null
      }
      </TopToolbar>
  );
};

const OrderFilter = (props) => {
  const translate = useTranslate();
  return (
  <Filter {...props}>
    <RemoteAutocompleteInput
      source="status"
      label={translate('ra.order.order_status')}
      remoteurl="/value/order-status-code"
      idproperty="code"
      valueproperty="code"
      hasTranslate
      translatePath="ra.order.status"
      alwaysOn
    />
    <RemoteAutocompleteInput
      source="order_cat_type"
      label={translate('ra.order.order_cat')}
      remoteurl="/value/order-cat-type"
      idproperty="code"
      valueproperty="code"
      hasTranslate
      translatePath="ra.order.category"
      alwaysOn
    />
    <SiteRemoteSelectInput
      source="site_id"
      label={translate('ra.order.site')}
      remoteurl={`/value/sites?site_user_id=${localStorage.getItem('user_id')}`}
      idproperty="id"
      valueproperty="name"
      alwaysOn
    />
    <TextInput source="keyword" label={translate('ra.order.keyword')} alwaysOn/>
  </Filter>
  )
}

const OrderList = (props) => {
  const translate = useTranslate();
  const locale = getLocale();
  const [open, setOpen] = React.useState(false);
  const [orderRecord, setOrderRecord] = React.useState(null);
  const toggleDrawer = () => {
    setOpen(!open);
  }
  return (
  <List
    {...props}
    title={translate('ra.page.order')}
    actions={<OrderListActions isDrawerOpen={open} toggleDrawer={toggleDrawer} record={orderRecord}/>}
    bulkActionButtons={false}
    sort={{ field: 'ref', order: 'DESC' }}
    filters={<OrderFilter />}
  >
    <Datagrid rowClick={
      (id, basePath, record) => {
        toggleDrawer();
        setOrderRecord(record);
      }
      } 
      isRowSelectable={() => {}} 
      hasBulkActions={false} 
      isRowExpandable={()=>{}}
    >
      <FunctionField label={translate('ra.order.order_status')}
        render={record => {
          const isExpired = checkDateExpired(`${record?.appointment_date}T${record?.appointment_time}Z`);
          const isEmergency = record?.is_emergency;
          const appointmentDateString = getFullDateTimeFormat(record?.appointment_date, record?.appointment_time, locale)
          const arrivalDate = record?.arrivalTime ? getFullDateTimeFormat(String(record?.arrivalTime).split(' ')[0],String(record?.arrivalTime).split(' ')[1], locale) : translate('ra.order.status.not_yet_arrived') ;
          const {label, color} = getClientPortalOrderStatus(record?.orderStatus?.code, isExpired);
          return (
            <>
              <Box style={{display: 'flex', flexWrap: 'wrap', gap: 4}}>
              <Chip color={color} label={translate(`ra.order.status.${label}`)} />
              {isEmergency ? <Chip color="primary" label={translate('ra.order.emergency')} /> : null}
              {record?.defect_order_id != null ? <Chip color='primary' label={translate('ra.order.defeat')} /> : null}
              </Box>
              <Typography style={{fontSize: '12px'}}>{translate('ra.order.appointment_time')}: {appointmentDateString}</Typography>
              <Typography style={{fontSize: '12px'}}>{translate('ra.order.arrival_time')}: {arrivalDate}</Typography>
              
            </>
          )
        }}
      />
      <TextField source="ref" label={translate('ra.order.order_ref')} />
      <TextField source="site.name" label={translate('ra.order.site')} />
      <FunctionField label={translate('ra.order.order_cat')} 
        render={record => {
          return locale === 'zh-TW' ? record?.orderCatType.name : record?.orderCatType.name_en;
        }}
      />
      <TextField source="remark" label={translate('ra.order.remark')} />
      <FunctionField label={translate('ra.order.pickup_worker')} render={(record) => {
        const workerName = record?.workerInfo?.full_name;
        return (
          <>
          <Typography>{workerName}</Typography>
          {
            record?.workerInfo?.licenses?.map(license => {
              return (
                <Typography key={`license-${license.worker_id}-${license.id}`} style={{fontSize: '12px', color: 'rgb(108 108 108)'}}>{license.type}</Typography>
              )
            })
          }
          </>
        )
      }} />
      <FunctionField label={translate('ra.order.order_item')} render={record => {
        return (
         <>
         {
          record?.details.map(orderItem => {
            return (
              <Typography key={`orderItem-${orderItem.id}`} style={{fontSize: '12px', color: 'rgb(108 108 108)'}}>{locale === 'zh-TW' ? orderItem.siteServiceItemName : orderItem.siteServiceItemNameEn}: {orderItem.qty}</Typography>
            )
          })
         }
         </> 
        )
      }}/>
    </Datagrid>
  </List>
  )
};

export default OrderList;