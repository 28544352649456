import React from "react"
import {Button, Typography, Box, Input, Checkbox, FormControlLabel, InputLabel, FormControl, InputAdornment, IconButton, Modal, Divider, Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";
import Visibility  from "@material-ui/icons/Visibility";
import VisibilityOff  from "@material-ui/icons/VisibilityOff";
import HowToRegIcon from '@material-ui/icons/HowToReg';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BasicRemoteSelectInput from "components/BasicRemoteSelectInput";
import myAxios from "helpers/axios";
import Config from "Config";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '93%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 8,
    overflowY: 'scroll',
    height: '100%',
  };

const TermsAndConditionAccordion = () => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography style={{fontWeight: 600}}>Terms and Conditions</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ol>
                    <li>服務
                        <ul>
                            <li>
                            i.	客戶通過WhatsApp /電話/ APP從客戶收到服務要求，會向3 Masons Limited提出相同的服務請求
                            </li>
                        </ul>
                    </li>
                    <li>
                        客戶責任<br/>
                        在本協議期限內，客戶應
                        <ul>
                            <li>在本協議期限內，客戶應</li>
                        </ul>
                    </li>
                    <li>
                        3 Masons Limited責任<br/>
                        3 Masons Limited應:
                        <ul>
                            <li>在訂單進行前, 確認工人為香港合法工人及持有相關牌照, 及有需要時向客戶提供證明。</li>
                            <li>在訂單進行期間，工人必須攜帶3 Masons提供的認可證件及有需要時展示。</li>
                            <li>在訂單開始前及完成後，工人必須根據APP內的指示，完成指定工作流程，包括但不限於上傳清晰且有關該訂單項目的檢查和完工照片、以通順的文句填寫有關該訂單項目的檢查和完工報告。沒有完成訂單的指定工作流程，則視為未完成該訂單，而該訂單的費用則未進行結算，有關結算會因此而延期</li>
                            <li>在訂單完成後，工人會向分店負責人掃瞄完工收貨碼或者有效證明以作完工確認，所有確認完工之訂單會根據付款條款支付給予3 Masons Limited</li>
                        </ul>
                    </li>
                    <li>
                        標準價錢單項目
                        <ul>
                            <li>3 Masons Limited向客戶提供標準價錢單項目附件, 附件內的項目不需向客戶提供報價及可於現場立刻進行, 但進行需得到現場分店負責人同意, 包括掃瞄分店負責人的確認工程二維碼或其他證明。</li>
                        </ul>
                    </li>
                    <li>
                        客戶付款方式
                        <ul>
                            <li>客戶需於訂單完成後， 付款方法: 
                                <ul>
                                    <li>電子支付平台, 包括Payme / 轉數快等</li>
                                    <li>現金</li>
                                    <li>每月結算</li>
                                </ul>
                            </li>
                            <li>月結: 每一個月的首七天內，3 Masons Limited會以電郵向客戶發出一份月結單顯示出在上月所完成的訂單及其服務費。</li>
                            <li>
                            客戶在收到月結單日起計, 3 Masons Limited向客戶提供數期選項: 
                            <ul>
                                <li>超過14天付款, 額外收取5%附加費用, 之後每過14天再收取額外5%附加費</li>
                            </ul>
                            </li>
                            <li>支付需透過銀行/支票轉帳至月結單/發票所提供的銀行戶口, 客戶需提供入數證明。支付需以以銀行戶口到數為支付確認日期</li>
                        </ul>
                    </li>
                    <li>
                        保密和非競爭
                        <ul>
                            <li>客戶明白，在現場提供服務的員工可能會獲得某些訊息及資料，它們對本司來說可能是機密。客戶承諾及保證其員工保密處理所有這些信息和資料，並同意不透露給任何第三方。</li>
                            <li>所有由本司所提供的文件，報價單及客戶資料。客戶承諾及保證其員工保密處理所有這些信息和資料，並同意不透露給任何第三方。客戶同意將不會：
                                <ul>
                                    <li>直接或間接本司工人私底與客戶達成任何工作協議。</li>
                                    <li>直接或間接徵求或誘使3 Masons Limited員工或3 Masons Limited的其他服務提供商終止與3 Masons Limited的合作</li>
                                </ul>
                            </li>
                            <li>如有違反以上, 3 Masons Limited有權追討損失</li>
                        </ul>
                    </li>
                </ol>
            </AccordionDetails>
        </Accordion>
    )
}

const SingleFieldInput = ({name, label, onChange, width = '47%', type="text", ...rest}) => {
    return (
        <Box sx={{width: {xs: '100%', sm: `${width}` }, marginTop: {xs: 0, lg: 10}}}>
        <FormControl style={{ width: '100%' }} variant="standard">
            <InputLabel htmlFor={`standard-${name}`}>{label}</InputLabel>
            <Input
            id={`standard-${name}`}
            type={type}
            onChange={e=> onChange(e.target.value)}
            fullWidth
            required
            {...rest}
            />
        </FormControl>
        </Box>
    )
}

const SectionTitle = ({title}) => (<Typography style={{color: '#6e6565', fontWeight: 600, fontSize: 18, marginTop: 20}}>{title}</Typography>);

const RegisterPage = ({onSuccess}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [username, setUsername] = React.useState('');
    const [fullname, setFullname] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [contactNumber, setContactNumber] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [br, setBr] = React.useState('');
    const [businessNature, setBusinessNature] = React.useState('');
    const [siteName, setSiteName] = React.useState('');
    const [siteAddress, setSiteAddress] = React.useState('');
    const [district, setDistrict] = React.useState('');
    const [siteUrl, setSiteUrl] = React.useState('');
    const [siteLat, setSiteLat] = React.useState('');
    const [siteLng, setSiteLng] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [error, setError] = React.useState([]);
    const [submitting, setSubmitting] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const onSiteAddressChange = React.useCallback(async(val) => {
        if (!val) {
            setSiteUrl('');
            setSiteLat('');
            setSiteLng('');
        } else {
            let queryParams = new URLSearchParams();
            queryParams.append('key', Config.googleMapApiKey);
            queryParams.append('address', val);
            setSiteUrl(`https://maps.googleapis.com/maps/api/geocode/json?${queryParams.toString()}`);
            setSiteAddress(val);
        }
    }, [])

    React.useEffect(() => {
        async function fetchGeoApi() {
            const {data} = await myAxios.get(siteUrl);
            if (data?.status === 'OK') {
                setSiteLat(data?.results?.[0]?.geometry?.location?.lat);
                setSiteLng(data?.results?.[0]?.geometry?.location?.lng);
            } else {
                setSiteLat(null);
                setSiteLng(null);
            }
        }
        if (siteUrl!=='') {
            setTimeout(() => fetchGeoApi(), 500);
        }
        return clearTimeout();
    }, [siteUrl]);

    const handleFormSubmit = React.useCallback(async(e) => {
        e.preventDefault();
        setSubmitting(true);
        const formData = {
            username: username,
            password: password,
            email: email,
            full_name: fullname,
            contact_number: contactNumber,
            company_name: companyName,
            business_nature: businessNature,
            br: br,
            site_name: siteName,
            site_address: siteAddress,
            district_code: district,
            lat: siteLat,
            lng: siteLng
        };
        await myAxios.post(myAxios.addApiUrl('/client/account/register'), formData)
        .then(r => {
            setSubmitting(false);
            setOpen(false);
            onSuccess();
        }).finally(() => setSubmitting(false));
    }, [username, password,email, siteLat, siteLng, district, fullname, companyName, contactNumber, businessNature, br, siteAddress, siteName, onSuccess])
    return (
        <React.Fragment>
            <Button onClick={handleOpen} variant="contained" color="primary" startIcon={<HowToRegIcon/>}>Register</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box className="fix-header-panel" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Register Account
                    </Typography>
                    <Button style={{color: '#333'}} onClick={handleClose}><CloseIcon/></Button>
                    </Box>
                <form onSubmit={handleFormSubmit} >
                <br/>
                <SectionTitle title="User Account Info" />
                <Divider/>
                <Box style={{display: 'flex', flexWrap: 'wrap', gap: 4}}>
                    <SingleFieldInput name="username" label="Username" onChange={setUsername}/>
                    <br />
                    <Box sx={{width: {xs: '100%', sm: '47%'}, margin: {xs: 0, sm: 0}}}>
                    <FormControl style={{ width: '100%'}} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={e=> setPassword(e.target.value)}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            required
                        />
                    </FormControl>
                    </Box>
                    <br/>
                    <SingleFieldInput name="fullname" label="Fullname" onChange={setFullname}/>
                    <br/>
                    <SingleFieldInput name="email" label="Email" onChange={setEmail} type="email"/>
                    <br/>
                </Box>
                <br/>
                <SectionTitle title="Company Info" />
                <Divider/>
                <Box style={{display: 'flex', flexWrap: 'wrap', gap: 4}}>
                <SingleFieldInput name="company_name" label="Company Name" onChange={setCompanyName}/>
                <br />
                <SingleFieldInput name="contact_number" label="Contact Number" onChange={setContactNumber} />
                <br/>
                <SingleFieldInput placeholder="First 8 Digits" name="br" label="Business Registration No." onChange={setBr} type="number" minLength="8"/>
                <br />
                <BasicRemoteSelectInput remoteurl="/value/business-natures" idproperty="name" valueproperty="id" name="business_nature" label="Business Nature" onChange={setBusinessNature}/>
                <br />
                </Box>
                <br/>
                <SectionTitle title="Site Info" />
                <Divider/>
                <Box style={{display: 'flex', flexWrap: 'wrap', gap: 4}}>
                <SingleFieldInput name="site_name" label="Site Name" onChange={setSiteName}/>
                <br />
                <BasicRemoteSelectInput remoteurl="/value/district-code" idproperty="name" valueproperty="code" name="district" label="District" onChange={setDistrict}/>
                <br />
                <SingleFieldInput name="site_address" label="Site Address" width="100%" onChange={onSiteAddressChange}/>
                <Typography style={{fontSize: 10}}>{siteLat && siteLng ? 'Address ok.' : 'Invalid address.'}</Typography>
                <br />
                </Box>
                <Box>
                <FormControlLabel
                    control={
                    <Checkbox name="antoine" required={true} />
                    }
                    label="I agree to abide by the terms and conditions."
                />
                </Box>
                <TermsAndConditionAccordion/>
                <Button disabled={submitting} style={{marginTop: 20, marginBottom:20}} type="submit" variant="contained">Register Account</Button>
                </form>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default RegisterPage