import SiteRecipientsForm from './SiteRecipientsForm';
import {
  Create, useNotify, useRedirect, useRefresh
} from 'react-admin';

const SiteRecipientsCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`分店:${data?.site?.name}的聯絡人已建立`);
    redirect('/client/companies/'+data?.company_id+'/show/sites');
    refresh();
  }; 
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SiteRecipientsForm />
    </Create>
  )
};

export default SiteRecipientsCreate;