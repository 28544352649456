import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button, Datagrid, List, SimpleList, SimpleShowLayout, TextField, useGetList, useGetOne, useTranslate } from 'react-admin';
import { convertLocaleDate } from 'helpers/localeDate';
import { getLocale } from 'layout/MyAppBar';

const useStyles = makeStyles({
  field: {
    // These styles will ensure our drawer don't fully cover our
    // application when teaser or title are very long
    '& span': {
      display: 'inline-block',
      maxWidth: '20em'
    }
  }
});

const CompanyServiceItemLogButton = ({ companyId }) => {
  const translate = useTranslate();
  const locale = getLocale();
  const [showPanel, setShowPanel] = useState(false);
  const classes = useStyles();
  const { data, total, isLoading, error } = useGetList('admin/company-service-item-histories', {}, { field: 'created_at', order: 'DESC' }, { company_id: companyId });
  const handleClick = () => {
    setShowPanel(true);
  };

  const handleCloseClick = () => {
    setShowPanel(false);
  };

  return (
    <>
      <Button onClick={handleClick} label={translate('ra.serviceItem.log.view')}>
        <IconImageEye />
      </Button>
      <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
        <div>
          <Button label="Close" onClick={handleCloseClick}>
            <IconKeyboardArrowRight />
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell >{translate('ra.serviceItem.log.item_name')}</TableCell>
                <TableCell >{translate('ra.serviceItem.log.detail')}</TableCell>
                <TableCell >{translate('ra.serviceItem.log.updated_at')}</TableCell>
                <TableCell >{translate('ra.serviceItem.log.updated_by')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(data).map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.companyServiceItem.item.name}
                  </TableCell>
                  <TableCell align="right"><strong>{row.changed_attribute}: </strong>{`${row.changed_value} -> ${row.new_value}`}</TableCell>
                  <TableCell align="right">{convertLocaleDate(new Date(row.created_at), locale)}</TableCell>
                  <TableCell align="right">{row.created_by}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </Drawer>
    </>
  );
};

export default CompanyServiceItemLogButton;
