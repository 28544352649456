import {Typography, Box, Drawer, Paper, Divider, Avatar, Grid, CircularProgress, Chip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'; 
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import KeyboardDoubleArrowRightIcon from '@material-ui/icons/DoubleArrow';
import InfoIcon from '@material-ui/icons/Info';
import HistoryIcon from '@material-ui/icons/History';
import BuildIcon from '@material-ui/icons/Build';
import { checkDateExpired, getClientDefectStatus, getClientPortalOrderItemStatus, getClientPortalOrderStatus, getClientPortalOrderStatusDesc, getFullDateTimeFormat } from 'helpers/utils';
import { convertLocaleDate } from 'helpers/localeDate';
import useOrderHistory from 'hooks/useOrderHistory';
import useOrder from 'hooks/useOrder';
import useDefectOrder from 'hooks/useDefectOrder';
import { useTranslate } from 'react-admin';
import { getLocale } from 'layout/MyAppBar';


const OutlinedTextWithIcon = ({
    label,
    icon,
    handleOnClick = () => {}
}) => {
    return (
        <Box onClick={handleOnClick} sx={{color: 'rgb(97 94 94 / 87%)', fontWeight: 700, padding: '2px 8px', display: 'flex', alignItems: 'center', gap:8, border: `1px solid rgb(211 209 209 / 87%)`}}>
            {label} {icon}
        </Box>
    )
}

const LabelWithValue = ({
    label,
    value,
    children,
    expandable = false
}) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', my: 2}}>
            <Typography style={{color: 'rgb(97 94 94 / 87%)', fontSize: '10px', marginBottom: '-5px'}}>{label}</Typography>
            <Typography style={{color: 'rgb(34 31 31)'}}>{value}</Typography>
            <Box sx={{mt: 2}}>{children}</Box>
        </Box>  
    )
}

const SectionTitle = ({
  title,
  Icon 
}) => {
  return (
    <Typography style={{fontSize: 18, display: 'flex', alignItems: 'center', gap: 4, fontWeight: 700, marginTop: 8, marginBottom: 20}}>
      {Icon}
      {title}
    </Typography>
  )
}

const OrderHistoryDetail = ({
    i,
    history,
    totalCount,
    children
}) => {
    const locale = getLocale();
    const translate = useTranslate();
    const statusLabel = getClientPortalOrderStatus(history.status_code);
    return (
        <Box key={`status-${history.status_code}`} >
            <Box sx={{display: 'flex', flex:1}}>
                <CheckCircleIcon color={i === totalCount - 1 ? `primary` : `secodary`}/> 
                <Box sx={{display:'flex', justifyContent:'space-between', flex:1}}>
                    <Typography style={{color: 'rgb(62 58 58 / 100%)', fontWeight: 700, fontSize: 14}}>
                        {translate(`ra.order.status.${statusLabel.label}`)} 
                    </Typography>
                    <Typography style={{color: 'rgb(137 131 131 / 87%)', fontSize: '12px'}}>
                        {convertLocaleDate(new Date(history?.created_at), locale)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{minHeight: 21 ,borderLeft: `2px dashed rgb(137 131 131 / 87%)`, margin: '10px 0px 10px 10px', paddingLeft: '15px'}}>
                {children}
            </Box>
        </Box>
    )
}

const DetailResultPics = ({
    items,
    showLabel = true
}) => {
    if (items?.length <= 0 ) return null;
    return (
        <>
        {
            items?.map(item => {
                if (!item?.resultPics) return <Typography>未有完工照片</Typography>;
                return (
                    <>
                    {
                        showLabel ? 
                        <Typography>{item?.siteServiceItemName}</Typography> :
                        null
                    }
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 4}}>
                        {item?.resultPics?.map(resultPic => {
                            return (
                                <Box style={{flexBasis: '30%',maxWidth: '30%'}}>
                                    <a href={resultPic.staticServePath} target="_blank"><img src={resultPic.staticServePath} style={{width: '100%', maxWidth: 200}}/></a>
                                </Box>
                            )
                        })}
                    </Box>
                    </>
                )
            })
        }
        </>
    )
}

const OrderHistoryList = ({orderId}) => {
    const translate = useTranslate();
    const locale = getLocale();
    const {data: orderHistories, isLoading: orderHistoryIsLoading} = useOrderHistory(orderId);
    const {data: order, isLoading: orderIsLoading} = useOrder(orderId);
    const count = orderHistories?.meta?.totalCount;
    if (orderHistoryIsLoading || orderIsLoading ) return <CircularProgress/>;
    return (
        <Box id="order-status-history" sx={{p: 4}}>
            <SectionTitle title={translate('ra.order.order_history')} Icon={<HistoryIcon/>} />
            {orderHistories?.items?.length ? orderHistories?.items?.map((history, i) => {
            const stausDesc = getClientPortalOrderStatusDesc(history.status_code, order?.workerInfo?.full_name, order?.workerInfo?.profile?.contact_number, locale);
            return ( 
                <OrderHistoryDetail key={`${history.status_code}-${i}`} i={i} history={history} totalCount={count}>
                    <Box>
                      <Typography style={{fontSize: 14, color: 'rgb(108 120 120, 88%)'}}>
                        {stausDesc}
                      </Typography>
                      {
                        history?.status_code === 'finish_checking' ?
                        <>
                        <Typography style={{marginTop: 10}}>{translate('ra.order.checking_report')}</Typography>
                        <Box sx={{border: `1px solid rgb(108 120 120)`, p: 2, mb: 2}}>
                            <Typography style={{color: 'rgb(108 120 120)'}}>
                                {order?.checking_report ?
                                order?.checking_report : 
                                translate('ra.order.no_report_msg')
                                }
                            </Typography>
                        </Box>
                        <DetailResultPics items={order?.details?.filter(d=> d.siteServiceItemCode === 'checking')}  showLabel={false}/>
                        </>
                        : null
                      }
                      {
                        history?.status_code === 'prepare_material' ?
                        <OrderItemList orderItems={order?.details} total={order?.total_price} />
                        : null
                      }
                      {
                        history?.status_code === 'completed' ?
                        <>
                            <Typography style={{marginTop: 10}}>{translate('ra.order.complete_report')}</Typography>
                            <Box sx={{border: `1px solid rgb(108 120 120)`, p: 2, mb: 2}}>
                                <Typography style={{color: 'rgb(108 120 120)'}}>
                                    {order?.complete_report ?
                                    order?.complete_report : 
                                    translate('ra.order.no_report_msg')
                                    }
                                </Typography>
                            </Box>
                            <DetailResultPics items={order?.details?.filter(d=> d.siteServiceItemCode !== 'checking')} />
                        </>
                        
                        : null
                      }
                    </Box>
                </OrderHistoryDetail>
            )
            }) : translate('ra.order.no_history_msg')}
        </Box>
    )
}

const OrderItemList = ({orderItems, total}) => {
    const translate = useTranslate();
    const locale = getLocale();
    if(!orderItems) return null;
    return (
        <Box sx={{p: 0}}>
            <Typography style={{fontSize: 16, color: 'rgb(108 120 120)',fontWeight: '700'}}>{translate('ra.order.order_item_detail')}</Typography>
            {
                orderItems.map((orderItem, i) => {
                    const status = getClientPortalOrderItemStatus(orderItem?.status);
                    return (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                            <Typography style={{fontSize: 14, color: 'rgb(108 120 120)',flexBasis: '60%', color: 'rgb(108 120 120)', fontWeight: 700}} key={`order-item-${orderItem?.siteServiceItemCode}-${i}`}>{locale === 'zh-TW' ? orderItem?.siteServiceItemName : orderItem?.siteServiceItemNameEn} ({translate(`ra.order.order_item_status.${orderItem?.status}`)})</Typography>
                            <Typography style={{fontSize: 14, color: 'rgb(108 120 120)',flexBasis: '10%'}}>{translate('ra.order.qty')}: <span style={{fontWeight: 700}}>{orderItem?.qty}</span></Typography>
                            <Typography style={{fontSize: 14, color: 'rgb(108 120 120)',flexBasis: '20%', textAlign: 'right', fontWeight: 700}}>{`HKD ${orderItem.price}`}</Typography>
                        </Box>
                    )
                })
            }
            <Typography style={{marginTop: 4,textAlign: 'right', fontWeight: 700, color: '#333'}}>{`${translate('ra.order.total')}: HKD ${total}`}</Typography>
        </Box>
    )
}

const DefectOrderSection = ({defectOrderId}) => {
    const translate = useTranslate();
    const locale = getLocale();
    const {data, isLoading} = useDefectOrder(defectOrderId);
    const appointmentDateString = getFullDateTimeFormat(data?.appointment_date, data?.appointment_time)
    if (!defectOrderId) return null;
    if (isLoading) return <CircularProgress />;

    return (
      <Box sx={{p: 4}}>
        <SectionTitle title={translate('ra.order.defectOrder')} Icon={<BuildIcon/>}/>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography style={{fontSize: 12, color: 'rgb(97 94 94 / 87%)'}}>{translate('ra.order.appointment_time')}: {appointmentDateString}</Typography>
            <Typography style={{fontSize: 12, color: 'rgb(97 94 94 / 87%)'}}>{translate('ra.order.pickup_worker')}: {data?.workerFullName}</Typography>
        </Box>
        <Divider/>
        <Box sx={{display: 'flex', flexDirection: 'column', py: 2}}>
          <Typography style={{marginTop: 8, marginBottom: 8, display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: 14}}><AutorenewIcon/>{translate('ra.order.order_progress')}</Typography>
          <Box sx={{display: 'flex', flexDirection: 'row', gap: 8}}>
            {data?.history?.length ? data?.history?.map((h, i) => {
              return (
                <Box key={i} sx={{display: 'flex', gap: 4, alignItems: 'center'}}>
                  <Box sx={{display: 'column'}}>
                    <Typography style={{fontSize: 15, color: 'rgb(97 94 94 / 95%)'}}>{translate(`ra.order.status.${h?.status_code}`)}</Typography>
                    <Typography style={{fontSize: 10, color: 'rgb(97 94 94 / 87%)'}}>{convertLocaleDate(new Date(h.created_at), locale)}</Typography>
                  </Box>
                  {data?.history?.length -1 !== i ? <KeyboardDoubleArrowRightIcon/> : null}
                </Box>
              )
            }) : <Typography tyle={{fontSize: 15, color: 'rgb(97 94 94 / 95%)'}}>{translate('ra.order.status.picked_up')}</Typography>
            }
          </Box>
          {
            <Box sx={{display: 'flex', flexDirection: 'row', gap: 4}}>
            {data?.result?.map(resultPic => {
                return (
                    <Box style={{flexBasis: '30%',maxWidth: '30%'}}>
                        <a href={resultPic.staticServePath} target="_blank"><img src={resultPic.staticServePath} style={{width: '100%', maxWidth: 200}}/></a>
                    </Box>
                )
            })}
            </Box>
          }
          </Box>
      </Box>
    )
}

const OrderDetailsDrawer = ({isOpen, toggleDrawer, record}) => {
    const translate = useTranslate();
    const isExpired = checkDateExpired(`${record?.appointment_date}T${record?.appointment_time}Z`);
    const isEmergency = record?.is_emergency;
    const appointmentDateString = getFullDateTimeFormat(record?.appointment_date, record?.appointment_time)
    const {label} = getClientPortalOrderStatus(record?.orderStatus?.code, isExpired);
    return (
      <Drawer
      anchor="right"
      open={isOpen}
      style={{padding: 20, width: '100%', }}
      PaperProps={{
        style : {width: '70%'}
      }}
      onClose={toggleDrawer}
      >
        <Paper elevation={2} style={{width: '100%'}}>
            <Box id="top-panel" sx={{
                width: '100%', 
                color: '#FFF',
                bgcolor: '#2e7d32', 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'space-between', 
                }}
            >
                <Box sx={{flexBasis: '45%', p: 1, display: 'flex', alignItems: 'center'}}>
                    {isEmergency ?
                        <Chip color="default" label={translate(`ra.order.emergency`)} style={{fontWeight: 700}} />
                        : null
                    }
                    {translate(`ra.order.status.${label}`)}。{record?.ref}
                </Box>
                <Box style={{flex:1}}></Box>
                <Box sx={{flexBasis: '45%', textAlign: 'right', p: 1}}>{translate(`ra.order.appointment_time`)}: {appointmentDateString}</Box>
            </Box>
            <Box sx={{}}>
            {record?.workerInfo ? 
            <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, p: 2}}>
                <Box><Avatar alt={record?.workerInfo?.full_name} src={record?.workerInfo?.profilePic.data} /></Box>
                <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection:'column'}}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 4}}>
                        <Typography style={{fontWeight: 700}}>
                            {`${record?.workerInfo?.full_name}`}<span style={{fontSize: 10, fontWeight: 300}}>({record?.workerInfo?.profile?.wid})</span>
                        </Typography>
                        <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}><StarIcon color="primary"/>4.99</Box>
                    </Box>
                    <Box sx={{mb: 4, display: 'flex', gap: 8}}>
                        <OutlinedTextWithIcon 
                            label={`${translate(`ra.order.tel`)}: ${record?.workerInfo?.profile?.contact_number ? record?.workerInfo?.profile?.contact_number : translate('ra.order.not_set') }`} 
                            icon={<LocalPhoneOutlinedIcon fontSize='small'/>} 
                        />
                        <OutlinedTextWithIcon 
                            label={translate(`ra.order.rate`)} 
                            icon={<StarIcon fontSize='small'/>} 
                        />
                        <OutlinedTextWithIcon 
                            label={translate(`ra.order.join_team`)} 
                            icon={<StarIcon fontSize='small'/>} 
                        />
                    </Box>
                    <Box>
                        <Typography style={{fontWeight: '700'}}>{translate(`ra.order.license`)}</Typography>
                        {
                            record?.workerInfo?.licenses.length ? record?.workerInfo?.licenses?.map(license => {
                                return (
                                    <Typography style={{fontSize: '12px'}} key={`license-${license.id}`}>{license?.type}</Typography>
                                )
                            }) : <Typography>{translate(`ra.order.no_license_msg`)}</Typography>
                        }
                    </Box>
                </Box>
            </Box>
            : <Box sx={{p: 2}}><Typography style={{fontWeight: '700'}}>{translate(`ra.order.no_worker_msg`)}</Typography></Box>}
            <Divider/>
            {record?.defect_order_id ? <DefectOrderSection defectOrderId={record?.defect_order_id}/> : null}
            
            <Divider/>
            <OrderHistoryList orderId={record?.id} />
            <Divider/>
            <Box sx={{p: 4}}>
                <SectionTitle title={translate('ra.order.order_info')} Icon={<InfoIcon/>} />
                <Grid container spacing={2}>
                <Grid item xs={4}>
                    <LabelWithValue label={translate('ra.order.site')} value={record?.site?.name} />
                </Grid>
                <Grid item xs={4}>
                    <LabelWithValue label={translate('ra.order.order_cat')} value={translate(`ra.order.category.${record?.orderCatType?.code}`)} />
                </Grid>
                <Grid item xs={4}>
                    <LabelWithValue label={translate('ra.order.remark')} value={record?.remark ? record?.remark : translate('ra.order.no_remark')} />
                </Grid>
                <Grid item xs={12}>
                    <LabelWithValue label={translate('ra.order.remark_pics')}>
                        {
                            record?.orderRemarkImages?.length ?
                                record?.orderRemarkImages.map((item) => (
                                    <img
                                        src={`${item.staticServePath}`}
                                        alt={item.filename}
                                        loading="lazy"
                                        style={{maxWidth:'150px'}}
                                    />
                                ))
                            : translate('ra.order.no_remark_pics')
                        }
                    </LabelWithValue>
                </Grid>
                </Grid>
            </Box>
            </Box>
        </Paper>
      </Drawer>
    )
}

export default OrderDetailsDrawer;