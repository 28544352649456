import Config from 'Config';
import RemoteSelectInput from 'components/RemoteSelectInput';
import React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  useTranslate
} from 'react-admin';
import { useForm } from 'react-final-form';
import { Typography }  from "@material-ui/core";

const SiteLatInput = (props) => {
  const form = useForm();
  form.change(props.source, props.value);
  return (
    <TextInput
      {...props}
      style={{display: 'none'}}
    />
  )
}

const SiteLngInput = (props) => {
  const form = useForm();
  form.change(props.source, props.value);
  return (
    <TextInput
      {...props}
      style={{display: 'none'}}
    />
  )
}

const SiteForm = (props) => {
  const [siteUrl, setSiteUrl] = React.useState('');
  const [siteLat, setSiteLat] = React.useState('');
  const [siteLng, setSiteLng] = React.useState('');
  const [error, setError] = React.useState('');
  const handleAddressChange = React.useCallback(async(e) => {
    if (!e.target.value) {
      // Clear Address
      setSiteUrl('');
      setSiteLat('');
      setSiteLng('');
    } else {
      let queryParams = new URLSearchParams();
      queryParams.append('key', Config.googleMapApiKey);
      queryParams.append('address', e.target.value);
      setSiteUrl(`https://maps.googleapis.com/maps/api/geocode/json?${queryParams.toString()}`);
    }
  }, [])
  const translate = useTranslate();
  
  React.useEffect(() => {
    async function fetchGeoApi() {
        const response = await fetch(siteUrl);
        const data = await response.json();
        if (data?.status === 'OK' && data?.results?.length > 0) {
            setSiteLat(data?.results?.[0]?.geometry?.location?.lat);
            setSiteLng(data?.results?.[0]?.geometry?.location?.lng);
        } else {
          setError('error');
        }
    }
    if (siteUrl!==''){
      setTimeout(() => fetchGeoApi(), 500);
    }
    return clearTimeout();
  }, [siteUrl]);

  return (
    <SimpleForm {...props}>
      <TextInput type="hidden" label={false} source="company_id" initialValue={localStorage.getItem('company_id')} disabled/>
      <TextInput label={translate('ra.site.name')} source="name" validate={required()}/>
      <TextInput label={translate('ra.site.address')} onChange={handleAddressChange} source="address" validate={required()}/>
      {/* <Typography style={{fontSize: 10}}>{siteLat && siteLng ? 'Address ok.' : 'Invalid address.'}</Typography> */}
      <TextInput label={translate('ra.site.contact')} source="contact" validate={required()}/>
      <TextInput label={translate('ra.site.business_hour')} source="business_hour" validate={required()}/>
      <RemoteSelectInput
        remoteurl="/value/district-code"
        source="district_code"
        idproperty="code"
        valueproperty="name"
        validate={required()}
        label={translate('ra.site.district')}
       />
      <TextInput type="hidden" source="active" label={false} disabled initialValue={1} style={{display: 'none'}}/>
      <SiteLatInput type="hidden" source="lat" label={false} value={siteLat}/>
      <SiteLngInput type="hidden" source="lng" label={false} value={siteLng}/>
    </SimpleForm>
  )
}

export default SiteForm;