import React from "react";
import myAxios from "helpers/axios";

export const useCompanyServiceItems = (params) => {
    const [output, setOutput] = React.useState({
        isLoading: false,
        data: null
    })

    React.useEffect(() => {
        const searchParams = new URLSearchParams(params);
        const fetch = async() => {
            await myAxios.get(myAxios.addApiUrl(`/client/company-service-items?${searchParams}`))
            .then(resp=> setOutput({
                isLoading: false,
                data: resp.data
            }));
        };
        fetch();
    }, [])

    return output;
}
