import React from "react";
import { 
    sanitizeListRestProps,
    Edit,
    SimpleForm, 
    Toolbar,
    SaveButton,
    DeleteButton,
    BooleanInput,
    useRedirect,
    useNotify, 
    useRefresh
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

  const WorkerSkillsetEditToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton  label="儲存"/>
      <DeleteButton label="刪除" undoable = {false} />
    </Toolbar>
  );

  const WorkerSkillsetForm = (props) => (
    <SimpleForm {...props}>
          <BooleanInput source="water" label="水工" />
          <BooleanInput source="electricity" label="電工" />
          <BooleanInput source="painting" label="油漆" />
          <BooleanInput source="drainage" label="渠務" />
          <BooleanInput source="plastering" label="灰泥" />
          <BooleanInput source="window" label="窗戶" />
          <BooleanInput source="stove" label="爐具" />
    </SimpleForm>
  )
  
export const WorkerSkillsetEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`項目已更新`);
    redirect('/admin/worker-users/'+data?.worker_id+'/show/skillsets');
    refresh();
  };  
  return (
    <Edit {...props} undoable = {false} onSuccess={onSuccess}>
      <WorkerSkillsetForm toolbar={<WorkerSkillsetEditToolbar />} />
    </Edit>
  )
};