import {
  Create, useNotify, useRedirect, useRefresh
} from 'react-admin';
import SiteForm from './SiteForm';

const SiteCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`分店:${data.name}已建立`);
    redirect('/client/companies/'+data?.company_id+'/show/sites');
    refresh();
  }; 
  
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SiteForm />
    </Create>
  )
};

export default SiteCreate;