import React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  NumberInput,
} from 'react-admin';
import OrderItemCompanyServiceItemRemoteSelectInput from 'components/orderItem/OrderItemCompanyServiceItemRemoteSelectInput';

const OrderItemCreateForm = (props) => {
    console.log(props);
  return (
    <SimpleForm {...props}>
      <OrderItemCompanyServiceItemRemoteSelectInput
        source="site_service_item_id"
        label="工程項目"
        remoteurl={`/value/order-item-company-service-items`}
        idproperty="id"
        valueproperty="itemName"
        validate={required()}
        alwaysOn
        fullWidth={false}
        />
        <NumberInput
        source="qty"
        label="數量"
        defaultValue="1"
        fullWidth={false}
        />
        <NumberInput
        source="price"
        label="自訂價錢  (單價)"
        fullWidth={false}
        />
        <TextInput source="order_id" disabled />
    </SimpleForm>
  )
    ;
}

export default OrderItemCreateForm;