// in src/Dashboard.js
import * as React from "react";
import { Popover, Box, Button, Paper,Typography, Chip, Grid } from '@material-ui/core';
import { Datagrid, FunctionField, List, TextField, useLocale, usePermissions, useRefresh, useTranslate } from "react-admin";
import Config from "Config";
import StarIcon from '@material-ui/icons/Star';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ApartmentIcon from '@material-ui/icons/Apartment';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import StoreIcon from '@material-ui/icons/Store';
import useValue from "hooks/useValue";
import _ from "lodash";
import { checkDateExpired, getClientPortalOrderStatus, getFullDateTimeFormat } from "helpers/utils";
import OrderDetailsDrawer from "resource/order/OrderDetailDrawer";

const Dashboard = () => {
    const translate = useTranslate();
    const locale = useLocale();
    const permissions = usePermissions();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [orderRecord, setOrderRecord] = React.useState(null);
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    }
    let fakeProps = {
        basePath: "/client/orders",
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        exporter: false,
        pagination: false,
        sort: {
            field: 'created_at',
            order: 'DESC'
        },
        actions: null, // toolbar
        bulkActionButtons: false,
        history: {},
        location: { pathname: "/", search: "", hash: "", state: undefined },
        match: { path: "/", url: "/", isExact: true, params: {} },
        options: {},
        permissions: null,
        resource: "client/orders",
        filterDefaultValues: {
            filterExpired: true
        }
    }

    const handleOrderCreate = React.useCallback(() => {
        window.location.href = Config.publicHost+'/#/client/orders/create';
    }, []);
    const handleNroList = React.useCallback(() => {
        window.location.href = Config.publicHost+'/#/client/non-regular-order-item-requests';
    }, []);
    const handleAddSite = React.useCallback(() => {
        window.location.href = Config.publicHost+'/#/client/sites/create';
    }, []);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleSitePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSiteShow = React.useCallback((siteId) => {
        if (siteId) {
        window.location.href = Config.publicHost+'/#/client/sites/'+siteId+'/show';
        } else {
            window.location.href = Config.publicHost+'/#/client/sites/show';
        } 
    }, []);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const company = useValue('company', {id: localStorage.getItem('company_id') || 0});
    const sites = useValue('sites', {company_id: localStorage.getItem('company_id') || 0});
    const siteIdsArr = localStorage.getItem('site_ids')?.split(',');
    const currentSites = sites?.res?.data?.filter(s=> siteIdsArr.includes(String(s.id))).length ?  sites?.res?.data?.filter(s=> siteIdsArr.includes(String(s.id))) : sites?.res?.data;
    const currentSiteLabel = currentSites?.length > 0 ? _.map(currentSites, 'name').join(',') : '全部';
    const orderLocked = permissions.permissions?.is_verified === 0;
    const refresh = useRefresh();
    return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 4}}>
        <OrderDetailsDrawer isOpen={drawerOpen} toggleDrawer={toggleDrawer} record={orderRecord}/>
        <Paper style={{padding: 16}} elevation={2}>
            <Typography style={{fontWeight: 900, fontSize: 20}}>{translate('ra.dashboard.title')}</Typography>
            <Typography style={{fontSize: 19, display: 'flex', alingItems: 'center'}}><EmojiPeopleIcon />{translate('ra.dashboard.welcome')} - {localStorage.getItem('full_name')}</Typography>
            <Typography style={{marginTop: 5, display: 'flex', alingItems: 'center'}}><ApartmentIcon/>{translate('ra.dashboard.company')} - <span style={{fontWeight: 'bold'}}>{company?.res?.data?.name}</span> <Box sx={{minWidth: 50}}></Box> <StoreIcon/> {translate('ra.dashboard.site')} - <span style={{fontWeight: 'bold'}}>{currentSiteLabel}</span></Typography>
        </Paper>
        <Paper style={{padding: 16}}  elevation={2}>
            {orderLocked ? <Typography variant="h6" style={{color: '#7d7878', marginBottom: 10}}>{translate('ra.account.is_verifing')}</Typography> : null}
            <Grid container spacing={2}>
            <Grid item id="site-shortcut" xs={12} md={6} lg={3}>
                <Button startIcon={<StoreIcon />} onClick={handleSitePopoverClick} style={{width: '100%'}} color="primary" variant="outlined">{translate('ra.dashboard.site_qr_code')}</Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    style={{padding: 4}}
                >
                    <Box style={{display: 'flex', flexDirection: 'flex', flexWrap: 'wrap', width: '100%'}}>
                    {
                        !sites?.res?.loading && sites?.res?.data?.length ? 
                        currentSites?.map(site => {
                            return <Button key={`site-key-${site.id}`} style={{width: '100%'}} variant="contained" color="primary" onClick={() => handleSiteShow(site.id)}>{site.name}</Button>
                        }) : <Button onClick={() => handleSiteShow()}>{translate('ra.dashboard.latest_order')}</Button>
                    }
                    </Box>
                </Popover>
            </Grid>
            <Grid item id="create-order" xs={12} md={6} lg={3}>
                <Button disabled={orderLocked} startIcon={<StarIcon/>} onClick={handleOrderCreate} style={{width: '100%'}} color="primary" variant="outlined">{translate('ra.dashboard.new_order')}</Button>
            </Grid>
            <Grid item id="nro-list" xs={12} md={6} lg={3}>
                <Button startIcon={<WhatshotIcon/>} onClick={handleNroList} style={{width: '100%'}} color="primary" variant="outlined">{translate('ra.dashboard.check_nro')}</Button>
            </Grid>
            <Grid item id="add-site" xs={12} md={6} lg={3}>
                <Button startIcon={<AddCircleIcon/>} onClick={handleAddSite} style={{width: '100%'}} color="primary" variant="outlined">{translate('ra.dashboard.add_site')}</Button>
            </Grid>
            </Grid>
        </Paper>
        <Paper style={{padding: 16}}  elevation={2}>
        <Box>
            <Box sx={{mb: 4,display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Typography variant="h5">{translate('ra.dashboard.latest_order')}</Typography>
            <Button onClick={() => refresh()} color="primary" variant="contained">{translate('ra.action.refresh')}</Button>
            </Box>
            {
                <List {...fakeProps}>
                    <Datagrid rowClick={
                    (id, basePath, record) => {
                        toggleDrawer();
                        setOrderRecord(record);
                    }
                    } 
                    isRowSelectable={() => {}} 
                    hasBulkActions={false} 
                    isRowExpandable={()=>{}}
                    >
                    <FunctionField label={translate('ra.order.order_status')}
                        render={(record,id) => {
                        const isExpired = checkDateExpired(`${record?.appointment_date}T${record?.appointment_time}Z`);
                        const isEmergency = record?.is_emergency;
                        const appointmentDateString = getFullDateTimeFormat(record?.appointment_date, record?.appointment_time, locale)
                        const arrivalDate = record?.arrivalTime ? getFullDateTimeFormat(String(record?.arrivalTime).split(' ')[0],String(record?.arrivalTime).split(' ')[1], locale) : translate(`ra.order.status.not_yet_arrived`) ;
                        const {label, color} = getClientPortalOrderStatus(record?.orderStatus?.code, isExpired);
                        return (
                            <>
                            <Box style={{display: 'flex', flexWrap: 'wrap', gap: 4}}>
                            <Chip color={color} label={translate(`ra.order.status.${label}`)} />
                            {isEmergency ? <Chip color="primary" label={translate('ra.order.emergency')} /> : null}
                            {record?.defect_order_id != null ? <Chip color='primary' label={translate('ra.order.defect')} /> : null}
                            </Box>
                            <Typography style={{fontSize: '12px'}}>{translate('ra.order.appointment_time')}: {appointmentDateString}</Typography>
                            <Typography style={{fontSize: '12px'}}>{translate('ra.order.arrival_time')}: {arrivalDate}</Typography>
                            </>
                        )
                        }}
                    />
                    <TextField source="ref" label={translate('ra.order.order_ref')} />
                    <TextField source="site.name" label={translate('ra.order.site')} />
                    <FunctionField label={translate('ra.order.order_cat')} render={(record)=> {
                        return locale === 'zh-TW' ? record?.orderCatType.name : record?.orderCatType.name_en;
                    }}/>
                    <TextField source="remark" label={translate('ra.order.remark')} />
                    <FunctionField label={translate('ra.order.pickup_worker')} render={(record) => {
                        const workerName = record?.workerInfo?.full_name;
                        return (
                        <>
                        <Typography>{workerName}</Typography>
                        {
                            record?.workerInfo?.licenses?.map(license => {
                            return (
                                <Typography key={`license-${license.worker_id}-${license.id}`} style={{fontSize: '12px', color: 'rgb(108 108 108)'}}>{license.type}</Typography>
                            )
                            })
                        }
                        </>
                        )
                    }} />
                    <FunctionField label={translate('ra.order.order_item')} render={record => {
                        return (
                        <>
                        {
                        record?.details.map(orderItem => {
                            return (
                            <Typography key={`orderItem-${orderItem.id}`} style={{fontSize: '12px', color: 'rgb(108 108 108)'}}>{locale === 'zh-TW' ? orderItem.siteServiceItemName : orderItem.siteServiceItemNameEn}: {orderItem.qty}</Typography>
                            )
                        })
                        }
                        </> 
                        )
                    }}/>
                    </Datagrid>
                </List>
            }
        </Box>
        </Paper>
    </Box>
    )
};

export default Dashboard