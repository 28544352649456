// in src/MyLayout.js
import { Layout, AppBar } from 'react-admin';
import MyAppbar from './MyAppBar'; 
import { MyMenu } from './MyMenu';
const MyLayout = (props) => <Layout
    {...props}
    appBar={MyAppbar}
    menu={MyMenu}
/>;

export default MyLayout;