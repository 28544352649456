import React from 'react';
import {
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  SelectInput,
  useRecordContext,
  useTranslate
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const NroSlipQuickCreateButton = props => {
  const translate = useTranslate();
  const record = useRecordContext(props.record);
  const [showDialog, setShowDialog] = React.useState(false);
  const [create, { loading }] = useCreate('client/non-regular-order-item-request-slips', );
  const notify = useNotify();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    let formData = {
      approve_type: values.approve_type,
      request_id: values.request_id
    };
    create(
      { payload: { data: formData } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          //props.onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        }
      }
    );
  };

  const handleOnChange = (val) => {
    console.log(val);
  };
  return (
    <>
      <Button disabled={record.status !== 'pending_approve'} style={{marginBottom: 4}} variant='contained' onClick={handleClick} label={translate('ra.nro.update_request_btn')}>
        {
          loading ?
          <CircularProgress /> :
          <IconContentAdd />
        }
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>{translate('ra.nro.update_request_btn')}</DialogTitle>

        <FormWithRedirect
          resource="client/non-regular-order-item-request-slips"
          save={handleSubmit}
          onChange={handleOnChange}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => {
            return (
            <>
              <DialogContent>
                <TextInput type="hidden" source="request_id" label={false} defaultValue={record?.id} disabled fullWidth/>
                <SelectInput source="approve_type" label={translate('ra.nro.status')} choices={[
                  {id: 'approved', name:translate('ra.nro.statuses.approved')},
                  //{id: 'approved_add_company_service_item_only', name:'批准 (只加入報價單)'},
                  {id: 'rejected', name: translate('ra.nro.statuses.rejected')},
                  //{id: 'void', name: '取消'},
                ]} fullWidth required/>
                  {/* <ImageInput source="files" label="客戶證明圖片" accept="image/*" multiple required>
                    <ImageField source="src" title="title" />
                  </ImageInput> */}
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}}
        />
      </Dialog>
    </>
  );
}

export default NroSlipQuickCreateButton;