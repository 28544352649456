import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

const myTheme = merge({}, defaultTheme, {
    palette: {
        default: {
            
        },
        primary: {
            light: '#4caf50',
            main: '#2e7d32',
            dark: '#1b5e20',
            contrastText: '#fff',
        },
        secondary: {
            light: '#6ec6ff',
            main: '#2196f9',
            dark: '#0069c0',
            contrastText: '#fff',
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    overrides: {
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: 'white', // Some CSS
            },
            primaryButton: {
                backgroundColor: '#4caf50'
            }
        },
        RaMenuItemLink: {
            active: {
                borderLeft: '3px solid #e75836',
                color: '#e75836',
                fontWeight: 'bold',
            },
            root: {
                borderLeft: '3px solid #fff', // invisible menu when not active, to avoid scrolling the text when selecting the menu
            },
        },
    },
});

export default myTheme;