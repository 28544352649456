import React, { useState, useEffect } from 'react';
import {Select, MenuItem, FormControl, InputLabel, Box} from "@material-ui/core";
import axios from 'helpers/axios';

const BasicRemoteSelectInput = ({width = '47%', ...props}) => {
    const [options, setOptions] = useState([]);
    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
        console.log(event.target.value);
        props.onChange(event.target.value);
        setValue(event.target.value);
    };
    const [init, setInit] = useState(false);
    const remoteurl = props.remoteurl;
    useEffect(() => {
        if (!init){
            axios.get(axios.addApiUrl(remoteurl))
            .then((response) => {
                setOptions(response.data)
            })
            .then(setInit(true));
        }
    },[init, remoteurl, options]);
    return (
        <Box sx={{ marginTop: {xs: 0, sm: 0}, width: {xs: '100%', sm: '47%'} }}>
        <FormControl style={{ width: '100%' }}>
            <InputLabel>{props.label}</InputLabel>
            <Select {...props} id={`standard-${props.name}-select`} value={value} onChange={handleChange}>
                {
                    options.map( row => {
                        return (
                            <MenuItem key={row[props.valueproperty]} value={row[props.valueproperty]}>
                            {row[props.idproperty]}
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
        </Box>
    )
};

export default BasicRemoteSelectInput;