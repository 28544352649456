import { convertLocaleDate } from "helpers/localeDate";
import { getClientNroStatus } from "helpers/utils";
import { getLocale } from "layout/MyAppBar";
import React, { cloneElement } from "react";
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    EditButton,
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
    ShowButton,
    ReferenceField,
    useTranslate
} from 'react-admin';

const NroRequestListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    );
};

const NroRequestList = (props) => {
    const translate = useTranslate();
    const locale = getLocale();
    return (
    <List
        {...props}
        actions={<NroRequestListActions />}
        bulkActionButtons={false}
        sort={{ field: 'created_at', DefectOrder: 'DESC' }}
    >
        <Datagrid rowClick="show">
            {/* <OrderField source="order_id" label="訂單編號" /> */}
            <ReferenceField link={false} label={translate('ra.nro.order_ref')} source="order.id" reference="client/orders">
                <TextField source="ref" />
            </ReferenceField>
            <FunctionField source="order.orderCatType.name" label={translate('ra.order.order_cat')} 
                render={resource => {
                    return locale === 'zh-TW' ? resource?.order.orderCatType.name : resource?.order.orderCatType.name_en;
                }}
            />
            <TextField source="order.site.name" label={translate('ra.order.site')} />
            <FunctionField label={translate('ra.order.order_status')} 
                render={record => {
                    const statusCode = getClientNroStatus(record.status, locale);
                    return translate(`ra.nro.statuses.${statusCode}`)
                    
                }}
            />
            <FunctionField label={translate('ra.nro.created_at')}
                render={record => {
                    const date = new Date(`${record.created_at}`);
                    return (
                        <div>
                            {convertLocaleDate(date,locale)}
                        </div>
                    )
                }}
            />
            <FunctionField label={translate('ra.nro.updated_at')}
                render={record => {
                    const date = new Date(`${record.updated_at}`);
                    return (
                        <div>
                            {convertLocaleDate(date, locale)}
                        </div>
                    )
                }}
            />
            <FunctionField render={
                record => {
                return (
                <>
                {record.status === 'pending_add_item' && <EditButton label={translate('ra.nro.edit_btn')} record={record} />}
                {record.status !== 'pending_add_item' && <ShowButton label={translate('ra.nro.view_btn')} record={record} />}
                </>
                )}
            }/>
        </Datagrid>
    </List>
    )
};

export default NroRequestList;