import OrderCreateForm from './OrderCreateForm';
import {
  Create, useNotify, useRefresh, useRedirect, useTranslate
} from 'react-admin';

const OrderCreate = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(translate('ra.notification.order_created'));
    redirect('/client/orders');
    refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <OrderCreateForm />
    </Create>
  )
};

export default OrderCreate;