import RemoteSelectInput from 'components/RemoteSelectInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  email,
  useTranslate
} from 'react-admin';

const SiteRecipientsForm = (props) => {
  const translate = useTranslate();
  return (
    <SimpleForm {...props}>
      <TextInput type="hidden" label={false} source="company_id" initialValue={localStorage.getItem('company_id')} disabled/>
      <SiteRemoteSelectInput
        source="site_id"
        label={translate('ra.siteRecipients.site')}
        remoteurl={`/value/sites?site_user_id=${localStorage.getItem('user_id')}&company_id=${localStorage.getItem('company_id')}`}
        idproperty="id"
        valueproperty="name"
        validate={required()}
        alwaysOn
      />
      <RemoteSelectInput
        source="message_type"
        label={translate('ra.siteRecipients.type')}
        remoteurl="/value/notification-message-type"
        idproperty="code"
        valueproperty="code"
        validate={required()}
        hasTranslation
        translatePath="ra.siteRecipients"
        alwaysOn
        fullWidth
      />
      <TextInput source="email" label={translate('ra.siteRecipients.email')} validate={email()}/>
    </SimpleForm>
  )
    ;
}

export default SiteRecipientsForm;