// in src/MyAppBar.js
import * as React from 'react';
import { AppBar, useTranslate, useSetLocale, useLocale} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MyUserMenu from './MyUserMenu';
//import logo from '../../public/3masons_logo.png.png';

const defaultLocale = 'en';

export const getLocale = () => {
    const storedLocale = localStorage.getItem('locale');
    return storedLocale || defaultLocale;
}

export const setLocale = (locale) => {
    localStorage.setItem('locale', locale);
}

const LocaleSwitcher = () => {
    //const setLocale = useSetLocale();
    const handleLocaleChange = (value) => {
        setLocale(value);
        window.location.reload(); // Reload the app to apply the new locale
    };
    const locale = getLocale();

    return (
        <div>
            <Button variant={locale === 'zh-TW' ? 'contained' : 'outlined'} disabled={locale === 'zh-TW'} onClick={() => handleLocaleChange('zh-TW')}>繁中</Button>
            <Button variant={locale === 'en' ? 'contained' : 'outlined'} disabled={locale === 'en'} onClick={() => handleLocaleChange('en')}>English</Button>
        </div>
    );
};

const MyAppBar = (props) => {
    const translate = useTranslate();
    return (
    <AppBar
        style={{
            background: '#2E8B57',
            "& .RaAppBarTitle": {
                flex: 1,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
        }}
        userMenu={<MyUserMenu />}
        {...props}
    >   
    <img src="/3masons_logo.png" alt="3-masons company logo" style={{width: '40px', height: '40px', marginRight: '10px'}}/>
        <Box sx={{flex: 1, display: { xs: 'none', sm: 'block' }}}>
            <Typography
                style={{flex: 1}}
                variant="h6"
                color="inherit"
                id="react-admin-title"
            >
                {translate('ra.appbar.title')}
            </Typography>
        </Box>
        <LocaleSwitcher/>
    </AppBar>
    )
};

export default MyAppBar;