import { stringify } from 'query-string';
import axios from 'helpers/axios';

// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const dataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query1 = {
            'page': JSON.stringify(page),
            'per-page': JSON.stringify(perPage),
            'sort': (order === "DESC" ? '-' : '') + field,
        };
        const query = { ...query1, ...params.filter };
        return axios.get(axios.addApiUrl(`/${resource}?${stringify(query)}`))
            .then((response) => ({
                data: response.data.items,
                total: response.data.meta.totalCount,
            }));
    },

    getOne: (resource, params) =>
        axios.get(axios.addApiUrl(`/${resource}/${params.id}`))
            .then((response) => ({
                data: response.data,
            })),

    getMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                axios.get(axios.addApiUrl(`/${resource}/${id}`))
            )
        ).then(responses => ({ data: responses.map((response) => response.data) })),

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const target = params.target;
        const query1 = {
            'page': JSON.stringify(page),
            'per-page': JSON.stringify(perPage),
            'sort': (order === "DESC" ? '-' : '') + field,
            [target]: params.id,
        };
        const query = { ...query1, ...params.target };
        return axios.get(axios.addApiUrl(`/${resource}?${stringify(query)}`))
            .then((response) => ({
                data: response.data.items,
                total: response.data.meta.totalCount,
            }));
    },

    create: (resource, params) => {
        // Order override
        if (resource == "client/orders" && params.data.remarkImages !== null) {
            let formData = new FormData();
            formData.append("company_id", params.data.company_id);
            if (params.data.remarkImages !== undefined) {
                params.data.remarkImages.map((img) => {
                    formData.append("remarkImages[]", img.rawFile);
                    return false;
                })
            }
            formData.append("site_id", params.data.site_id);
            formData.append("appoint_type", params.data.appoint_type);
            formData.append("order_cat_type", params.data.order_cat_type);
            if (params.data.orderItems !== undefined) {
                params.data.orderItems.map((item) => {
                    formData.append("orderItems[]", JSON.stringify(item));
                    return false;
                })
            }
            if (params.data.assignedWorker !== undefined) {
                formData.append("assignedWorker", params.data.assignedWorker);
            }

            if (params.data.appoint_type == 'appointment') {
                formData.append("appointment_date", params.data.appointment_date);
                formData.append("appointment_time", params.data.appointment_time);
            }
            formData.append("allow_dispatch", params.data.allow_dispatch ? 1 : 0);
            formData.append("remark", params.data.remark || "");
            formData.append("is_emergency", params.data.is_emergency ? 1 : 0);
            return (
                axios.post(axios.addApiUrl(`/${resource}`), formData, {
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=--------------------------078340406503680381688910",
                    },
                }).then((response) => ({
                    data: response.data,
                }))
                    .catch((error) => {
                        if (error.response.status === 422) {
                            let message = 'Data Validation Failed: ';
                            error.response.data.map(e => {
                                message += e.message + ' ';
                                return Promise.reject(message);;
                            })
                            console.log(message);
                            return Promise.reject(message);
                        }
                        return Promise.reject(error.message);
                    })
            )
        }
        // Multiple file upload -- for NOR request slip
        if (params.data.files != null) {
            let formData = new FormData();
            formData.append("request_id", params.data.request_id);
            formData.append("approve_type", params.data.approve_type);
            if (params.data.files !== undefined) {
                params.data.files.map((img) => {
                    formData.append("files[]", img.rawFile);
                    return false;
                })
            }
            return (
                axios.post(axios.addApiUrl(`/${resource}`), formData, {
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=--------------------------078340406503680381688910",
                    },
                }).then((response) => ({
                    data: response.data,
                }))
                    .catch((error) => {
                        if (error.response.status === 422) {
                            let message = 'Data Validation Failed: ';
                            error.response.data.map(e => {
                                message += e.message + ' ';
                                return Promise.reject(message);;
                            })
                            console.log(message);
                            return Promise.reject(message);
                        }
                        return Promise.reject(error.message);
                    })
            )
        }
        // Single file upload -- for worker lincese
        if (params.data.file != null) {
            let formData = new FormData();
            formData.append("worker_id", params.data.worker_id);
            formData.append("file", params.data.file.rawFile);
            formData.append("expiry_date", params.data.expiry_date);
            formData.append("type", params.data.type);
            return (
                axios.post(axios.addApiUrl(`/${resource}`), formData, {
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=--------------------------078340406503680381688910",
                    },
                }).then((response) => ({
                    data: response.data,
                }))
                    .catch((error) => {
                        if (error.response.status === 422) {
                            let message = 'Data Validation Failed: ';
                            error.response.data.map(e => {
                                message += e.message + ' ';
                                return Promise.reject(message);;
                            })
                            console.log(message);
                            return Promise.reject(message);
                        }
                        return Promise.reject(error.message);
                    })
            )
            //});
        }
        return (
            axios.post(axios.addApiUrl(`/${resource}`), params.data)
                .then((response) => ({
                    data: response.data,
                }))
                .catch((error) => {
                    if (error.response.status === 422) {
                        let message = 'Data Validation Failed: ';
                        error.response.data.map(e => {
                            message += e.message + ' ';
                            return null;
                        })
                        console.log(message);
                        return Promise.reject(message);
                    }
                    return Promise.reject(error.message);
                })
        )
    },
    update: (resource, params) => {
        if (params.data.file != null) {
            let formData = new FormData();
            if (resource == 'admin/worker-licenses') {
                formData.append("worker_id", params.data.worker_id);
                formData.append("file", params.data.file.rawFile);
                formData.append("expiry_date", params.data.expiry_date);
                formData.append("type", params.data.type);
            }
            if (resource == 'admin/worker-profile-pics') {
                formData.append("worker_id", params.data.worker_id);
                formData.append("file", params.data.file.rawFile);
            }
            return (
                axios.put(axios.addApiUrl(`/${resource}/${params.id}`), formData, {
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=--------------------------078340406503680381688910",
                    },
                }).then((response) => ({
                    data: response.data,
                }))
                    .catch((error) => {
                        if (error.response.status === 422) {
                            let message = 'Data Validation Failed: ';
                            error.response.data.map(e => {
                                message += e.message + ' ';
                                return Promise.reject(message);;
                            })
                            console.log(message);
                            return Promise.reject(message);
                        }
                        return Promise.reject(error.message);
                    })
            )
        }
        return (
            axios.put(axios.addApiUrl(`/${resource}/${params.id}`), params.data)
                .then((response) => ({
                    data: response.data,
                }))
                .catch((error) => {
                    if (error.response.status === 422) {
                        let message = 'Data Validation Failed: ';
                        error.response.data.map(e => {
                            message += e.message + ' ';
                            return null;
                        })
                        console.log(message);
                        return Promise.reject(message);
                    }
                    return Promise.reject(error.message);
                })
        )
    },
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                axios.put(axios.addApiUrl(`/${resource}/${id}`), params.data)
            )
        ).then(responses => ({ data: responses.map((response) => response.data) })),

    delete: (resource, params) =>
        axios.delete(axios.addApiUrl(`/${resource}/${params.id}`))
            .then((response) => ({
                data: params.data
            })),

    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                axios.delete(axios.addApiUrl(`/${resource}/${id}`))
            )
        ).then(responses => ({ data: [] })),
};

export default dataProvider;