import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    CardActions,
    ListButton,
    RefreshButton,
    useShowController,
    ReferenceField,
    FunctionField,
    useTranslate,
} from "react-admin";
import { Button, TableRow, TableCell, Typography } from '@material-ui/core';
import NroSlipQuickCreateButton from "./NroSlipQuickCreateButton";
import { getClientNroStatus } from "helpers/utils";
import { convertLocaleDate } from "helpers/localeDate";
import { useCompanyServiceItems } from "hooks/useCompanyServiceItems";
import _ from "lodash";
import BasicTable from "components/BasicTable";
import myAxios from "helpers/axios";
import { getLocale } from "layout/MyAppBar";

const NroRequestShowActions = ({ basePath, data }) => {
    const translate = useTranslate();
    return (
        <CardActions>
            <ListButton basePath={basePath} label={translate('ra.nro.list')}/>
            <RefreshButton label={translate('ra.action.refresh')}/>
            {/* <NroRequestLogButton requestId={data?.id} /> */}
        </CardActions>
    )
};

const COLUMNS = [
    'item_name',
    'qty',
    'unit_price',
    'total_price'
]

const renderOrderItemRow = (row) => {
    return (
        <TableRow
              key={`${row.id}-${row.qty}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row?.company_service_item_name}
              </TableCell>
              <TableCell >{row?.requested_qty}</TableCell>
              <TableCell >{row?.company_service_item_charge_price}</TableCell>
              <TableCell align="right">{Number(row?.requested_qty * row?.company_service_item_charge_price)}</TableCell>
        </TableRow>
    )
} 

const SpanningTable = ({total}) => {
    const translate = useTranslate();
    return (
        <>
        <TableRow>
            <TableCell rowSpan={1} />
            <TableCell colSpan={2} align="right"><Typography style={{fontWeight: 700}}>{translate('ra.nro.show.total')}:</Typography></TableCell>
            <TableCell align="right"><Typography style={{fontWeight: 700}}>{total}</Typography></TableCell>
        </TableRow>
        </>
    )
}

const NroRequestShow = (props) => {
    const translate = useTranslate();
    const locale = getLocale();
    const { record } = useShowController(props);
    const [submitting, setSubmitting] = React.useState();
    const {data : companyServiceItemList} = useCompanyServiceItems({company_id: record?.order?.company_id, order_cat_type:record?.order?.order_cat_type });
    // simple merge the company service item to the array
    // todo::the relation should simply return by the api is better
    const filteredCompanyServiceItemList = _.map(record?.details, (o) => o );
        // qty: o.requested_qty,
        // companyServiceItemId: o.company_service_item_id,
        // companyServiceItem: _.find(companyServiceItemList?.items, (item) => {
        //     return item.id === o.company_service_item_id;
        // }) || {},
    //}));
    const total = filteredCompanyServiceItemList.reduce((acc, curr) => {
        const itemPrice = curr.requested_qty * curr.company_service_item_charge_price;
        return acc + itemPrice;
    }, 0)
    const downloadPdf = async() => {
        try {
          myAxios.get(myAxios.addApiUrl(`/file/pdf/nro?request_id=${record.id}`), {
            responseType: "blob",
          }).then((response) => {
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
             const pdfWindow = window.open();
             pdfWindow.location.href = fileURL;            
          })
          .catch((error) => {
            console.log(error);
          });
        } catch (error) {
            return { error };
        }
    }
    return (
        <Show actions={<NroRequestShowActions />} {...props} >
            <SimpleShowLayout>
                <ReferenceField link={false} label={translate('ra.nro.order_ref')} source="order.id" reference="client/orders">
                    <TextField source="ref" />
                </ReferenceField>
                <FunctionField label={translate('ra.nro.status')}
                    render={record => {
                        const statusCode = getClientNroStatus(record.status);
                        return translate(`ra.nro.statuses.${statusCode}`);
                        
                    }}
                />
                <FunctionField source="order.orderCatType.name" label={translate('ra.order.order_cat')} 
                    render={resource => {
                        return translate(`ra.order.category.${resource.order.orderCatType?.code}`)
                    }}
                />
                <TextField source="approvedStatusChanger" label={translate('ra.nro.show.approver')}/> 
                <FunctionField label={translate('ra.nro.created_at')}
                    render={record => {
                        const date = new Date(`${record.created_at}`);
                        return (
                            <div>
                                {convertLocaleDate(date, locale)}
                            </div>
                        )
                    }}
                />
                <BasicTable 
                    columns={COLUMNS} 
                    data={filteredCompanyServiceItemList} 
                    renderRow={renderOrderItemRow}
                    spanningTable={<SpanningTable total={total}/>}
                    hasTranslation
                    translatePath="ra.nro.show.column"
                />
                <NroSlipQuickCreateButton record={record} />
                <Button onClick={downloadPdf} disabled={submitting} mode="contained" style={{ backgroundColor: '#333' }}>
                    {translate('ra.nro.show.download_pdf')}
                </Button>
            </SimpleShowLayout>
        </Show>
    )
}

export default NroRequestShow;