export const checkDateExpired = (date) => {
  const targetDate = new Date(date);
  const today = new Date();
  return today.getTime() > targetDate.getTime();
}

export const getFullDateTimeFormat = (date, time = null, locale = 'en') => {
  let dateTime;
  if (!time) {
    dateTime = new Date(date);
  } else {
    dateTime = new Date(`${date}T${time}Z`);
  }
  return dateTime.toLocaleString(locale);
}  

export const getClientPortalOrderItemStatus = (status_code) => {
  switch (String(status_code).toLowerCase()) {
    case 'complete' : 
    return '己完成';
    case 'incomplete': 
    return '未完工';
    case 'pending': 
    return '待施工';
    case 'waived' : 
    return '免收';
    default: 
    return '';
  }
}

export const getClientPortalOrderStatus = (status_code, isExpired = false) => {
    if (isExpired && status_code === 'pending_pick_up') return ({label: 'expired', color: 'default'});
    if (isExpired && status_code === 'picked_up') return ({label: 'expired', color: 'default'});
    let orderStatus = {
        label: '',
        color: '',
    }
    switch(status_code) {
        case 'pending_pick_up':
          orderStatus.color = 'secondary';
          orderStatus.label = 'pending_pick_up'
          break;
        case 'picked_up':
          orderStatus.color = 'secondary';
          orderStatus.label = 'picked_up';
          break;
        case 'set_off':
          orderStatus.color = 'secondary';
          orderStatus.label = 'set_off'
          break;
        case 'arrived':
          orderStatus.color = 'secondary';
          orderStatus.label = 'arrived'
          break;
        case 'finish_checking':
          orderStatus.color = 'secondary';
          orderStatus.label = 'finish_checking'
          break;
        case 'prepare_material':
          orderStatus.color = 'secondary';
          orderStatus.label = 'prepare_material'
          break;
        case 'in_progress':
          orderStatus.color = 'secondary';
          orderStatus.label = 'in_progress'
        break;
        case 'completed':
          orderStatus.color = 'primary';
          orderStatus.label = 'completed'
          break;
        case 'void':
          orderStatus.color = 'default';
          orderStatus.label = 'void'
        break;
        default:
          orderStatus.color = 'default';
          orderStatus.label = 'pending_pick_up'
    }
    return orderStatus;
}

export const getClientPortalOrderStatusDesc = (status, workerName, workerPhone, locale='en') => {
    if (status === 'picked_up') {
      
      return locale ==='en' ? `Handyman: ${workerName}, Tel: ${workerPhone || 'Not set'}` : `師傅: ${workerName} 已接單, 電話: ${workerPhone || '未設定'}` 
    }
    if (status === 'set_off') {
      //return '預計到達時間' 
      return '';
    }
    if (status === 'arrived') {
      return locale === 'en' ? 'Arrived, checking in progress.' : '己到達現場，正在檢查'; 
    }
    if (status === 'finish_checking') {
      return locale ==='en'? 'Checking images and report are uploaded.' : '已上載及完成檢查圖片及報告' 
    }
    if (status === 'prepare_material') {
      return '' 
    }
    return '';
}

export const getClientNroStatus = (status) => {
  switch (String(status).toLowerCase()) {
    case 'void' : 
    return 'void';
    case 'approved_add_company_service_item_only':
    return 'approved_add_company_service_item_only';
    case 'pending_approve': 
    return 'pending_approve';
    case 'approved': 
    return 'approved';
    case 'rejected' : 
    return 'rejected';	
    default: 
    return '';
  }
}

export const getClientDefectStatus = (status) => {
  switch (String(status).toLowerCase()) {
    case 'set_off' : 
    return '已出發';
    case 'arrived':
    return '已到達分店';
    case 'completed': 
    return '完成工程';	
    default: 
    return '';
  }
}