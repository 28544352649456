import React from "react";
import { UserMenu, MenuItemLink, useTranslate } from "react-admin";
import SettingsIcon from '@material-ui/icons/Settings';

const ChangePasswordMenu = React.forwardRef(({ onClick }, ref) => {
    const translate = useTranslate();
    return (
    <MenuItemLink
        ref={ref}
        to="/change-password"
        primaryText={translate('ra.account.change_password')}
        leftIcon={<SettingsIcon />}
        onClick={onClick} // close the menu on click
    />
    )
});

const MyUserMenu = props => (
    <UserMenu {...props}>
        <ChangePasswordMenu />
    </UserMenu>
);

export default MyUserMenu;

