import React, { useState } from 'react';
import { Link } from 'react-admin';
import {Button, Box, Input, InputLabel, FormControl,Typography, InputAdornment, IconButton} from "@material-ui/core";
import InputIcon from '@material-ui/icons/Input';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import myTheme from 'myTheme';
import myAxios from 'helpers/axios';
import Visibility  from "@material-ui/icons/Visibility";
import VisibilityOff  from "@material-ui/icons/VisibilityOff";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string'

const CustomIcon = () => {
  return <img width={200} height={200} src="/3masons_logo.png" alt="Custom image" />;
};

const ResetPassword = () => {
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [invalidPage, setInvalidPage] = React.useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const {search} = useLocation();
  const queryParamsValues = queryString.parse(search);
  const theme = createTheme(myTheme);

  const handleSubmit = React.useCallback(async(e) => {
    e.preventDefault();
    setSubmitting(true);
    await new Promise((res) => {
      setTimeout(() => {
        return res(123);
      }, 500)
    });
    const {data} = await myAxios.post(myAxios.addApiUrl(`/client/account/reset-password?token=${queryParamsValues.token}`),{password: password, confirm_password: confirmPassword}).catch(e=>setError('Error')).finally(setSubmitting(false));
    if (data) setSuccess('Success. You may login with new password now.');
  }, [password, confirmPassword, queryParamsValues?.token]);

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    async function checkTokenValid() {
      return await myAxios.get(myAxios.addApiUrl(`/client/account/token?token=${queryParamsValues?.token}`));
    }
    if (queryParamsValues?.token) {
      checkTokenValid().then(r => setInvalidPage(false)); 
    }
  }, [queryParamsValues?.token])

  const onPasswordSet = React.useCallback((e) => setTimeout(() => { setPassword(e.target.value) }, 300), []);
  const onConfirmPasswordSet = React.useCallback((e) => setTimeout(() => { setConfirmPassword(e.target.value) }, 300), []);

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{display: 'flex', height: '100vh'}}>
      <Box sx={{flexBasis: '25%', backgroundColor: '#2E8B57', display: { xs: 'none', sm: 'block' }}}>
        <Box style={{backgroundColor: '#2E8B57', width: '100%'}}></Box>
      </Box>
      <Box sx={{flexBasis: {xs: '100%', sm: '75%'}, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
        <Box style={{padding: 10}}>
          <Link to="/" style={{display: 'flex', alignItems:'center'}}><ArrowBack />Back</Link>
        </Box>
        <Box style={{padding: 10, backgroundColor: 'transparent', textAlign: 'center'}}>
          <CustomIcon />
          {
            invalidPage ?
            <Typography variant='h6'>Invalid page request.</Typography>
            : 
            <React.Fragment>
            <Typography variant='h6'>Reset Password</Typography>
            <form onSubmit={handleSubmit}>
              <Box>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={showPassword1 ? 'text' : 'password'}
                      onChange={onPasswordSet}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
              </Box>
              <Box>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={showPassword2 ? 'text' : 'password'}
                      onChange={onConfirmPasswordSet}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
              </Box>
              <Button style={{marginTop: 20}} disabled={submitting || !password || !confirmPassword} type="submit" variant="outlined" color="primary" startIcon={<InputIcon/>}>Submit</Button>
            </form>
            </React.Fragment>
          }
          {error ? error : ''}
          {success ? success : ''}
        </Box>
      </Box>
    </Box>
    </ThemeProvider>
  );
};

export default ResetPassword;