import React, { Fragment, cloneElement } from "react";
import {
  ReferenceField,
  List,
  useListContext,
  useShowController,
  sanitizeListRestProps,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  BulkDeleteButton,
  Toolbar,
  TopToolbar,
  CreateButton,
  SaveButton,
  DeleteButton,
  ShowButton,
  EditButton,
  CloneButton,
  required,
  EmailField,
  NumberField,
  DateField,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const SiteUserListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} label="新增" />
    </TopToolbar>
  );
};

const SiteUserBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const SiteUserFilter = (props) => (
  <Filter {...props}>
    <RemoteAutocompleteInput
      source="company_id"
      label="公司"
      remoteurl="/value/companies"
      idproperty="id"
      valueproperty="name"
      validate={required()}
      alwaysOn
    />
  </Filter>
);

const SiteUserEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const SiteUserForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      source="username"
      label="用戶帳號"
      validate={required()}
      fullWidth
    />
    <TextInput
      source="full_name"
      label="用戶名稱"
      validate={required()}
      fullWidth
    />
    <RemoteAutocompleteInput
      source="company_id"
      label="公司"
      remoteurl="/value/companies"
      idproperty="id"
      valueproperty="name"
      validate={required()}
      fullWidth
    />
    <TextInput
      source="password"
      label="密碼"
      fullWidth
    />
    <TextInput
      source="email"
      label="Email"
      fullWidth
    />
    <SelectInput source="status" label="狀態" choices={[
      { id: '10', name: 'Active' },
      { id: '0', name: 'Inactive' },
    ]} />
  </SimpleForm>
)

export const SiteUserList = (props) => (
  <List
    {...props}
    filters={<SiteUserFilter />}
    actions={<SiteUserListActions />}
    bulkActionButtons={<SiteUserBulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="company_id" reference="admin/companies" label="公司"><TextField source="name" label="公司" /></ReferenceField>
      <TextField source="username" label="用戶帳號" />
      <TextField source="full_name" label="名稱" />
      <EmailField source="email" />
      <NumberField source="status" />
      <DateField source="created_at" label="創建日期" showTime/>
      <DateField source="updated_at" label="更新日期" showTime/>
      <ShowButton label="詳情" />
      <EditButton label="編輯" />
      <CloneButton label="複製" />
    </Datagrid>
  </List>
);

export const SiteUserShow = (props) => {
  const { record } = useShowController(props);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="company_id" label="描述" />
        <TextField source="username" label="服務" />
        <TextField source="fullname" label="Token" />
      </SimpleShowLayout>
    </Show>
  )
};

export const SiteUserCreate = (props) => (
  <Create {...props}>
    <SiteUserForm />
  </Create>
);

export const SiteUserEdit = (props) => (
  <Edit {...props} undoable={false} >
    <SiteUserForm toolbar={<SiteUserEditToolbar />} />
  </Edit>
);