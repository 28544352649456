import React from "react";

const { default: myAxios } = require("helpers/axios")

const useDefectOrder = (order_id) => {
    const [axiosResp, setAxiosResp] = React.useState({
        isLoading: true,
        data : null,
    });
    React.useEffect(() => {
        const fetch = async() => {
            await myAxios.get(myAxios.addApiUrl(`/client/defect-orders/${order_id}`))
            .then(resp=> setAxiosResp({
                isLoading: false,
                data: {
                    ...resp?.data,
                    items: resp?.data?.items?.reverse()
                },
            }));
        }
        fetch();
    }, [])
    
    return axiosResp;
}

export default useDefectOrder