import React from 'react';
import {
  CardActions,
  ListButton,
  RefreshButton,
  Show,
  ReferenceManyField,
  Tab,
  ReferenceField,
  TabbedShowLayout,
  TextField,
  Datagrid,
  EditButton,
  DeleteButton,
  DateField,
  Button,
  Link,
  ImageField,
  FunctionField,
  SimpleShowLayout,
  ArrayField,
} from 'react-admin';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import BooleanNumField from 'components/BooleanNumField';
import Card from '@material-ui/core/Card';
import VoidOrderModal from './VoidOrderModal';
import UpdateAssignedWorkerModal from './UpdateAssignedWorkerModal';
import { convertLocaleDate } from 'helpers/localeDate';

const AddNewOrderItemButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: "/admin/order-items/create",
        // Here we specify the initial record for the create view
        state: { record: { order_id: record?.id } },
      }}
      label="加入新訂單項目"
    >
      <ChatBubbleIcon />
    </Button>
  )
};

const AddDefectOrderButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: "/admin/defect-orders/create",
        // Here we specify the initial record for the create view
        state: { record: { order_id: record?.id, order_cat_type: record?.order_cat_type } },
      }}
      label="建立執漏單"
    >
      <ChatBubbleIcon />
    </Button>
  )
}

const OrderShowActions = ({ basePath, data }) => {
  return (
    <CardActions>
      <ListButton basePath={basePath} />
      <RefreshButton />
      <AddNewOrderItemButton record={data} />
      {
        data?.defect_order_id ?
          <div>[已建立執漏單]</div> :
          <AddDefectOrderButton record={data} />
      }
      {
        data?.status == 'pending_dispatch' || data?.status == 'picked_up' || data?.status == 'pending_pick_up' ?
        <UpdateAssignedWorkerModal record={data} />
        : <div>[師傅已出發, 未能更改]</div>
      }
      {
        data?.job_id || (data?.status !== 'picked_up' && data?.status !== 'pending_pick_up') ?
          (
            data?.status == 'void' ?
              <div>[訂單已取消]</div>
              :
              <div>[訂單不能取消]</div>) :
          <VoidOrderModal record={data} />
      }
    </CardActions>
  )
};

const nonRegularOrderItemAside = () => {
  return (
    <div>
      加入非常規訂單項目
    </div>
  )
}

const OrderShow = (props) => {
  return (
    <Show actions={<OrderShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="訂單資料">
          <EditButton />
          <TextField source="ref" label="訂單參考編號" />
          <ReferenceManyField label="訂單日誌" reference="admin/order/order-history" target="order_id">
            <Datagrid>
              <TextField source="status_code" label="Status" />
              <TextField source="statusChanger" label="負責人" />
              <FunctionField label="時間"
                render={record => {
                  const date = new Date(`${record.created_at}`);
                  return (
                    <div>
                      {convertLocaleDate(date)}
                    </div>
                  )
                }}
              />
            </Datagrid>
          </ReferenceManyField>
          <FunctionField label="訂單建立時間"
                render={record => {
                  const date = new Date(`${record.created_at}`);
                  return (
                    <div>
                      {convertLocaleDate(date)}
                    </div>
                  )
                }}
              />
          <FunctionField label="預約時間"
                    render={record => {
                      const date = new Date(`${record.appointment_date}T${record.appointment_time}Z`); 
                      const dateStr = date.toLocaleString();
                      return (
                        <div>
                          {dateStr}
                        </div>
                      )
                    }}
                  />
          <BooleanNumField source="allow_dispatch" label="派送至師傅APP" />
          <BooleanNumField source="is_emergency" label="急單" />
          <FunctionField label="訂單創立者"
            render={record => {
              return (
                <>
                  {record.requested_by == null ?
                    `管理員: ${record?.admin?.username}`
                    : `用戶: ${record?.requestedBy?.username}`
                  }
                </>
              )
            }}
          />
          <TextField source="orderCatType.name" label="訂單類別" />
          <TextField source="company.name" label="公司名稱" />
          <TextField source="site.name" label="分店名稱" />
          <TextField source="total_price" label="訂單總價" />
          <TextField source="remark" label="訂單備註欄" />
          <ImageField label="訂單備註圖片" source="orderRemarkImages" src="staticServePath" />

        </Tab>
        <Tab label="訂單詳細內容" path="items">
          <ReferenceManyField label="" reference="admin/order-items" target="order_id">
            <Datagrid>
              <TextField source="siteServiceItemName" label="項目名稱" />
              <TextField source="qty" label="數量" />
              <TextField source="price" label="價錢 (單價)" />
              <TextField source="status" label="Status" />
              <EditButton />
              <DeleteButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="師傅工程詳細內容" path="job">
          <ReferenceField addLabel={false} reference="admin/worker-jobs" source="job_id" >
            <SimpleShowLayout>
              <TextField source="status" label="工程Status" />
              <Card variant="outlined" style={{ maxWidth: '500px', display: 'flex', border: '1px solid', padding: '5px' }}>
                <div style={{ flexBasis: '50%' }}>
                  <ImageField source="workerProfilePic.data" src="data" />
                </div>
                <div style={{ flexBasis: '50%', minWidth: '100px', display: 'flex', flexDirection: 'column' }}>
                  <p style={{ fontSize: '6px', color: '#ccc' }}>師傅編號</p>
                  <TextField label="師傅編號" source="workerProfile.wid" />
                  <p style={{ fontSize: '6px', color: '#ccc' }}>姓名/用戶名稱</p>
                  <FunctionField label="姓名" render={record => `${record?.workerProfile?.first_name} ${record?.workerProfile?.last_name} / ${record?.workerUser}`} />
                  <p style={{ fontSize: '6px', color: '#ccc' }}>性別</p>
                  <FunctionField label="姓別" render={record => record.workerProfile.sex == 'm' ? '男' : '女'} />
                  <p style={{ fontSize: '6px', color: '#ccc' }}>聯絡電話</p>
                  <TextField source="workerProfile.contact_number" label="聯絡電話" />
                </div>
              </Card>
              <p>工程詳細項目</p>
              <ArrayField source="jobDetails" label="項目">
                <Datagrid>
                  <TextField label="項目名稱" source="workerServiceItemName" />
                  <TextField label="數量" source="qty" />
                  <TextField label="Unit Cost" source="price" />
                  <TextField label="Status" source="statusName" />
                  <DateField showTime={true} label="建立時間" source="created_at" />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </ReferenceField>
        </Tab>
        <Tab label="工程完工照片" path="result-pics">
          <ArrayField source="details">
              <Datagrid>
                  <TextField label="項目名稱" source="siteServiceItemName" fullWidth/>
                  <ImageField label={false} source="resultPics" src="staticServePath" title="圖片" />
              </Datagrid>
          </ArrayField>
          
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
};

export default OrderShow;