import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLogin, useNotify } from 'react-admin';
import {Button, Box, Input, InputLabel, FormControl, InputAdornment, IconButton, Typography} from "@material-ui/core";
import Visibility  from "@material-ui/icons/Visibility";
import VisibilityOff  from "@material-ui/icons/VisibilityOff";
import InputIcon from '@material-ui/icons/Input';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import RegisterPage from 'components/Account/RegisterPage';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import myTheme from 'myTheme';

const CustomIcon = () => {
  return <img width={200} height={200} src="/3masons_logo.png" alt="Custom image" />;
};

const MyLoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const login = useLogin();
  const notify = useNotify();
  const history = useHistory();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = React.useCallback(async(e) => {
    e.preventDefault();
    if (!username || !password) return setError('Please fill in username and password.');
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(setSubmitting(true));
      }, 100);
    });
    await login({ username, password })
      .then(() => {
        notify('Logged in successfully');
        history.push('/');
      })
      .catch(() => setError('Invalid Username or Password'));
    setSubmitting(false)
  }, [history, login, username, password, notify]);

  const handleRegisterSuccess = React.useCallback(() => {
    setRegisterSuccess(true);
  }, [])

  const theme = createTheme(myTheme);

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{display: 'flex', height: '100vh'}}>
      <Box sx={{flexBasis: '25%', backgroundColor: '#2E8B57', display: { xs: 'none', sm: 'block' }}}>
        <Box style={{backgroundColor: '#2E8B57', width: '100%'}}></Box>
      </Box>
      <Box sx={{flexBasis: {xs: '100%', sm: '75%'}}}>
        <Box style={{padding: 10, backgroundColor: 'transparent', textAlign: 'center'}}>
        <CustomIcon />
        <form onSubmit={handleSubmit}>
          <FormControl style={{ m: 1, width: '25ch' }} variant="standard">
            <InputLabel htmlFor="standard-username">Username</InputLabel>
            <Input
              id="standard-username"
              type={'text'}
              onChange={e=> setUsername(e.target.value)}
            />
          </FormControl>
          <br />
          <FormControl style={{ m: 1, width: '25ch', marginBottom: 20 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                onChange={e=> setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
          </FormControl>
          {error?<p style={{marginTop: 0, color: 'crimson', fontSize: '13px', marginBottom: 0}}>{error}</p>:null}
          <br/>
          <Button disabled={submitting} type="submit" variant="outlined" color="primary" startIcon={<InputIcon/>}>Login</Button>
          <br/>
          <Button href="#/forget-password" variant='text' startIcon={<ContactSupportIcon/>} color="primary">Forget Password?</Button>
        </form>
        <br/>
        <RegisterPage onSuccess={handleRegisterSuccess}/>
        {registerSuccess ? <Typography variant='h6' style={{marginTop : 10}}>Thank you for your registration. You may now login to the cleint portal.</Typography> : null}
        </Box>
      </Box>
    </Box>
    </ThemeProvider>
  );
};

export default MyLoginPage;