import React from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import { Input, InputLabel, InputAdornment, FormControl, IconButton, Box, Button } from '@material-ui/core';
import Visibility  from "@material-ui/icons/Visibility";
import VisibilityOff  from "@material-ui/icons/VisibilityOff";
import myAxios from 'helpers/axios';
import { useAuthProvider } from 'react-admin';
import MyLayout from 'layout/MyLayout';

const ChangePasswordPage = () => {
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [showPassword3, setShowPassword3] = React.useState(false);
  const [error, setError] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [passwordForm, setPasswordForm] = React.useState({
    password: '',
    new_password: '',
    confirm_password: '',
  });
  const authProvider = useAuthProvider();
  const translate = useTranslate();
  const redirect = useRedirect();
  const handleSubmit = React.useCallback(async(e) => {
    e.preventDefault();
    setSubmitting(true);
    await myAxios.post(myAxios.addApiUrl('/client/account/change-password'), passwordForm).then(async(r)=> {
      authProvider.logout();
      redirect('/login');
    }).catch(e => {
      if (e.response.status === 422) {
        console.log(e.response.data);
        setError(e.response.data);
      }
    }).finally(() => {
      setSubmitting(false);
    })
  }, [passwordForm])
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleClickShowPassword3 = () => setShowPassword3((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <MyLayout>
        <h1>{translate('ra.account.change_password')}</h1>
        <form onSubmit={handleSubmit}>
            <Box>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">{translate('ra.account.current_password')}</InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword1 ? 'text' : 'password'}
                onChange={e=> setPasswordForm({...passwordForm, password: e.target.value})}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            </Box>
            <Box>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">{translate('ra.account.new_password')}</InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword2 ? 'text' : 'password'}
                onChange={e=> setPasswordForm({...passwordForm, new_password: e.target.value})}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            </Box>
            <Box>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">{translate('ra.account.confirm_password')}</InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword3 ? 'text' : 'password'}
                onChange={e=> setPasswordForm({...passwordForm, confirm_password: e.target.value})}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword3}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword3 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            </Box>
            <Box style={{marginTop: 20}}>
              <Button disabled={submitting} type="submit" variant="contained">{translate('ra.account.change_btn')}</Button>
            </Box>
            <p style={{fontStyle: 'italic', fontWeight: '900'}}>{translate('ra.account.description')}</p>
          </form>
          {error ? 
              error.map(t => {
                return (
                  <div style={{color: 'red'}}>
                    {t.message}
                  </div>
                )
              })
          : null}
    </MyLayout>
  );
};

export default ChangePasswordPage;