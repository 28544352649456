import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, SaveButton, DeleteButton, useNotify, useRefresh, useRedirect, Edit } from 'react-admin';
import SiteForm from './SiteForm';

const SiteEditToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton label="儲存" />
      <DeleteButton label="刪除" undoable={false} />
    </Toolbar>
  );

  const useStyles = makeStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  });

const SiteEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
      notify(`分店已更新`);
      redirect('/client/companies/' + data.company_id + '/show/sites');
      refresh();
    };
    return (
      <Edit {...props} undoable={false} onSuccess={onSuccess} >
        <SiteForm toolbar={<SiteEditToolbar />} />
      </Edit>
    )
};

export default SiteEdit