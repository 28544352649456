import React from 'react';
import {
  SimpleForm,
  TextInput,
  ArrayInput,
  required,
  SimpleFormIterator,
  BooleanInput,
  ImageInput,
  ImageField,
  NumberInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  DateInput,
  useTranslate,
  CreateButton,
  Button,
  Link
} from 'react-admin';
import Hidden from '@material-ui/core/Hidden';
import RemoteSelectInput from 'components/RemoteSelectInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import WorkerRemoteSelectInput from 'components/orderItem/WorkerRemoteSelectInput';
import CompanyServiceItemRemoteSelectInput from 'components/orderItem/CompanyServiceItemRemoteSelectInput';
import { getLocale } from 'layout/MyAppBar';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

const availableTimeSlot = [
  {
    name: '00:00 至 09:00',
    id: '09:00:00'
  },
  {
    name: '9:00 至 12:00',
    id: '12:00:00'
  },
  {
    name: '12:00 至 14:00',
    id: '14:00:00'
  },
  {
    name: '14:00 至 16:00',
    id: '16:00:00'
  },
  {
    name: '16:00 至 18:00',
    id: '18:00:00'
  },
  {
    name: '18:00 至 21:00',
    id: '21:00:00'
  },
  {
    name: '21:00 至 23:59',
    id: '23:59:00'
  },
];

const AddNewCompanySiteButton = () => {
  const translate = useTranslate();
  const company_id = localStorage.getItem('company_id');
  return (
  <Button
    component={Link}
    to={{
      pathname: "/client/sites/create",
      // Here we specify the initial record for the create view
      state: { record: { company_id: company_id } },
    }}
    label={translate('ra.order.order_form.site_missing')}
  >
    <ChatBubbleIcon />
  </Button>
  )
};

const OrderCreateForm = (props) => {
  const translate = useTranslate();
  const companyId = localStorage.getItem('company_id');
  const userId = localStorage.getItem('user_id');
  const locale = getLocale();
  return (
    <SimpleForm {...props}>
      <h3>{translate('ra.order.order_info')}</h3>
      <TextInput type="hidden" label={false} source="company_id" initialValue={localStorage.getItem('company_id')} disabled/>
      <SiteRemoteSelectInput
        source="site_id"
        label={translate('ra.order.order_form.order_site')}
        remoteurl={`/value/sites?company_id=${companyId}&site_user_id=${userId}`}
        idproperty="id"
        valueproperty="name"
        validate={required()}
        alwaysOn
      />
      <AddNewCompanySiteButton />
      <br/>
      <RemoteSelectInput
        source="order_cat_type"
        label={translate('ra.order.order_form.order_cat')}
        remoteurl="/value/order-cat-type"
        idproperty="code"
        valueproperty={locale === 'zh-TW' ? 'name' : 'name_en'}
        validate={required()}
        alwaysOn
        fullWidth
      />
      <RadioButtonGroupInput
        source="appoint_type"
        label={translate('ra.order.order_form.order_appointment_type')}
        initialValue={"asap"}
        choices={[
          { id: 'asap', name: translate('ra.order.order_form.order_appointment_type_asap') },
          { id: 'appointment', name: translate('ra.order.order_form.order_appointment_type_book') },
        ]}
        required
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          let todayDate = new Date();
          let day = todayDate.getDate();
          let month = String(todayDate.getMonth() + 1).padStart(2, "0");
          let year = todayDate.getFullYear();
          let hourAddFour = String(todayDate.getHours() + 4).padStart(2, "0");
          let min = String(todayDate.getMinutes()).padStart(2, "0");
          let second = String(todayDate.getSeconds()).padStart(2, "0");
          const todayTimeslotOptionRender = availableTimeSlot.filter(r => {
            const optionDate = new Date(`${year}-${month}-${day} ${r.id}`);
            const currentDate = new Date(`${year}-${month}-${day} ${hourAddFour}:${min}:${second}`);
            return optionDate > currentDate;
          })
          return (
            <>
              {formData.appoint_type === 'appointment' ?
                <>
                  <DateInput
                    source="appointment_date"
                    label={translate('ra.order.order_form.order_appointment_type_date')}
                    InputProps={{ inputProps: { min: `${year}-${month}-${day}` } }}
                    {...rest}
                  />
                  {
                    formData.appointment_date !== undefined ?
                      <RadioButtonGroupInput
                        source="appointment_time"
                        label={translate('ra.order.order_form.order_appointment_type_slot')}
                        choices={formData.appointment_date == `${year}-${month}-${day}` ? todayTimeslotOptionRender : availableTimeSlot}
                        fullWidth /> : null
                  }
                </> :
                null
              }
            </>
          )
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <>
              {formData.appoint_type === 'asap' ?
                <BooleanInput
                  source="is_emergency"
                  defaultValue={false}
                  label={translate('ra.order.order_form.order_emergency')}
                  fullWidth
                /> :
                null
              }
            </>
          )
        }}
      </FormDataConsumer>
      <h3>{translate('ra.order.order_form.order_additional_info')}</h3>
      <TextInput
        source="remark"
        label={translate('ra.order.remark')}
        placeholder={translate('ra.order.order_form.order_remark_max_length')}
        fullWidth
      />
      <ImageInput
        source="remarkImages"
        label={translate('ra.order.remark_pics')}
        accept="image/*"
        labelMultiple={translate('ra.order.order_form.order_pics_max')}
        multiple
        fullWidth
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  )
    ;
}

export default OrderCreateForm;