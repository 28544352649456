import React, { useState } from 'react';
import { Link } from 'react-admin';
import {Button, Box, Input, InputLabel, FormControl,Typography} from "@material-ui/core";
import InputIcon from '@material-ui/icons/Input';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import myTheme from 'myTheme';
import myAxios from 'helpers/axios';

const CustomIcon = () => {
  return <img width={200} height={200} src="/3masons_logo.png" alt="Custom image" />;
};

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmit = React.useCallback(async(e) => {
    e.preventDefault();
    setSubmitting(true);
    await new Promise((res) => {
      setTimeout(() => {
        return res(123);
      }, 500)
    });
    const {data} = await myAxios.post(myAxios.addApiUrl('/client/account/request-password-reset'),{email}).catch(e=>setError('Invalid email.')).finally(setSubmitting(false));
    if (data) setSuccess('Please check your mailbox to reset password.');
  }, [email, setSubmitting]);

  const theme = createTheme(myTheme);

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{display: 'flex', height: '100vh'}}>
      <Box sx={{flexBasis: '25%', backgroundColor: '#2E8B57', display: { xs: 'none', sm: 'block' }}}>
        <Box style={{backgroundColor: '#2E8B57', width: '100%'}}></Box>
      </Box>
      <Box sx={{flexBasis: {xs: '100%', sm: '75%'}, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
        <Box style={{padding: 10}}>
          <Link to="/" style={{display: 'flex', alignItems:'center'}}><ArrowBack />Back</Link>
        </Box>
        <Box style={{padding: 10, backgroundColor: 'transparent', textAlign: 'center'}}>
        <CustomIcon />
        <Typography variant='h6'>Forget Password</Typography>
        <form onSubmit={handleSubmit}>
          <FormControl style={{ m: 1, width: '25ch' }} variant="standard">
            <InputLabel htmlFor="standard-username">Email</InputLabel>
            <Input
              id="standard-email"
              type={'text'}
              onChange={e=> setEmail(e.target.value)}
            />
          </FormControl>
          <br/>
          <Button style={{marginTop: 20}} disabled={submitting || !email} type="submit" variant="outlined" color="primary" startIcon={<InputIcon/>}>Submit</Button>
        </form>
        {error ? error : ''}
        {success ? success : ''}
        </Box>
      </Box>
    </Box>
    </ThemeProvider>
  );
};

export default ForgetPassword;