import React, { Fragment, cloneElement } from "react";
import * as _ from "lodash";
import {
  ReferenceField,
  Button,
  List,
  useShowController,
  Datagrid,
  TextField,
  Show,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  Toolbar,
  TopToolbar,
  RefreshButton,
  SaveButton,
  DeleteButton,
  EditButton,
  required,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
  FunctionField,
  Link,
  BooleanInput,
  useQuery,
  Loading,
  useNotify,
  useRefresh,
  useRecordContext,
  useTranslate
} from 'react-admin';
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import RemoteSelectInput from 'components/RemoteSelectInput';
import RemoteAutocompleteInput from "components/RemoteAutocompleteInput";
import SubCategoryRemoteSelectInput from "components/SubCategoryRemoteSelectInput";
import myAxios from "helpers/axios";
import CompanyServiceItemLogButton from "./CompanyServiceItemLogButton";
import { convertLocaleDate } from "helpers/localeDate";
import { getLocale } from "layout/MyAppBar";

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CompanyEditToolbar = props => {
  const translate = useTranslate(); 
  return (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label={translate('ra.action.save')} />
    <DeleteButton label={translate('ra.action.delete')} undoable={false} />
  </Toolbar>
  )
};

const CompanyForm = (props) => {
  const translate = useTranslate();
  <SimpleForm {...props}>
    <TextInput
      source="name"
      label={translate('ra.company.name')}
      validate={required()}
      fullWidth
    />
    <TextInput
      source="br"
      label={translate('ra.company.br')}
      validate={required()}
      fullWidth
    />
    <RemoteSelectInput
      source="business_nature"
      label={translate('ra.company.nature')}
      remoteurl="/value/business-natures"
      idproperty="id"
      valueproperty="name"
      validate={required()}
      alwaysOn
    />
    <TextInput
      source="contact_number"
      label={translate('ra.company.contact_number')}
      fullWidth
    />
    <TextInput
      source="email"
      label={translate('ra.company.email')}
      fullWidth
    />
    <TextInput source="billing_company_name" label={translate('ra.company.billing_company_name')} />
    <TextInput source="billing_address" label={translate('ra.company.address')} />
    <TextInput source="billing_contact_person" label={translate('ra.company.billing_contact_person')} />
    <TextInput source="billing_contact_number" label={translate('ra.company.billing_contact_number')} />
    <TextInput source="billing_contact_department" label={translate('ra.company.billing_department')} />
    <BooleanInput
      source="active"
      label="Active"
      fullWidth
    />
  </SimpleForm>
}

const AddNewCompanyServiceItemButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/admin/company-service-items/create",
      // Here we specify the initial record for the create view
      state: { record: { company_id: record?.id } },
    }}
    label="加入新報價項目"
  >
    <ChatBubbleIcon />
  </Button>
);

const AddNewCompanySiteButton = ({ record }) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
  );
  return (
  <Button
    component={Link}
    to={{
      pathname: "/client/sites/create",
      // Here we specify the initial record for the create view
      state: { record: { company_id: record?.id } },
    }}
    label={translate('ra.site.add')}
  >
    {
      isXSmall ? 
        <React.Fragment><ChatBubbleIcon /><Typography>{translate('ra.site.add')}</Typography></React.Fragment>
         : <ChatBubbleIcon /> 
    }
  </Button>
  )
};

const AddNewCompanySiteUserButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: "/admin/site-users/create",
        // Here we specify the initial record for the create view
        state: { record: { company_id: record?.id } },
      }}
      label="加入分店用戶"
    >
      <ChatBubbleIcon />
    </Button>
  )
};


const CompanyShowActions = ({ basePath, data }) =>  {
  const translate = useTranslate();
  return (
  <CardActions>
    <RefreshButton label={translate('ra.action.refresh')} />
    {/*<ListButton basePath={basePath} />
    <AddNewCompanyServiceItemButton record={data} /> */}
    <AddNewCompanySiteButton record={data} />
    {/*<AddNewCompanySiteUserButton record={data} /> */}
  </CardActions>
  )
};

const CompanyShowCompanyServiceItemFilter = (props) => {
  const translate = useTranslate();
  const locale = getLocale();
  return (
    <Filter {...props}>
      <RemoteAutocompleteInput
        source="category_code"
        label={translate('ra.serviceItem.cat')}
        remoteurl="/value/service-item-category"
        idproperty="code"
        valueproperty={locale === 'zh-TW' ? "name" :"name_en"}
        alwaysOn
      />
      <SubCategoryRemoteSelectInput
        source="sub_category_code"
        label={translate('ra.serviceItem.subCat')}
        remoteurl="/value/service-item-sub-category"
        idproperty="code"
        valueproperty={locale === 'zh-TW' ? "name" :"name_en"}
        alwaysOn
      />
    </Filter>
  )
}

const SiteRecipientAside = ({ record }) => {
  const { data, loading, error } = useQuery({
    type: 'getList',
    resource: 'client/site-recipients',
    payload: { pagination: { page: 1, perPage: 100 }, sort: { field: 'site_id', order: 'desc' }, filter: { company_id: record?.id } }
  });
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const transformed = _.groupBy(data, (i) => i.email);
  const handleDelete = React.useCallback(async (id) => {
    await myAxios.delete(myAxios.addApiUrl(`/client/site-recipients/${id}`))
      .then(resp => console.log(resp));
    notify(translate('ra.notification.notification_person_deleted'));
    refresh();
  }
  );
  return (
    <div style={{ width: 200, margin: '1em', marginTop: 0 }}>
      <Typography variant="h6">{translate('ra.company.site_notification')}</Typography>
      <Button
        component={Link}
        to={{
          pathname: "/client/site-recipients/create",
          // Here we specify the initial record for the create view
          state: { record: {  } },
        }}
        label={translate('ra.notification.notification_person_added')}
      >
        <ChatBubbleIcon />
      </Button>
      <Divider style={{ marginBottom: '10px' }} />
      <Typography variant="body2">
        {
          !loading && !error ? Object.entries(transformed).map((row) => {
            const grouped = _.groupBy(row[1], (b) => b.site.name);
            return (
              <>
                <Box>
                  <Typography style={{ fontWeight: '600', marginBottom: '5px' }}>{row[0]}</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                    {
                      Object.entries(grouped).map((row) => {
                        return (
                          <>
                            {
                              row[1].map(type =>
                                <Chip
                                  key={type.id}
                                  variant="outlined"
                                  label={`${type?.site?.name} - ${translate(`ra.siteRecipients.${type?.message_type}`)}`}
                                  onDelete={() => handleDelete(type.id)}
                                />)
                            }
                          </>
                        )
                      })
                    }
                  </Box>
                </Box>
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
              </>
            )
          }) : <Loading />
        }
      </Typography>
    </div>
  );
}

const CompanyServiceItemListActions = (props) => {
  const record = useRecordContext(props);
  return (
    <TopToolbar>
    </TopToolbar>
  )
};

export const CompanyShow = (props) => {
  const { record } = useShowController(props);
  const translate = useTranslate();
  const locale = getLocale();
  return (
    <Show actions={<CompanyShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label={translate('ra.company.title')}>
          <TextField source="name" label={translate('ra.company.name')} style={{ width: '50%' }} />
          <TextField source="br" label={translate('ra.company.br')} style={{ width: '50%' }} />
          <TextField source="contact_number" label={translate('ra.company.conatct_number')} />
          <TextField source="email" label={translate('ra.company.email')} />
          <TextField source="billing_company_name" label={translate('ra.company.billing_company_name')} />
          <TextField source="billing_address" label={translate('ra.company.billing_address')} />
          <TextField source="billing_contact_person" label={translate('ra.company.billing_contact_person')} />
          <TextField source="billing_contact_number" label={translate('ra.company.billing_contact_number')} />
          <TextField source="billing_contact_department" label={translate('ra.company.billing_department')} />
        </Tab>
        <Tab label={translate('ra.site.title')} path="sites">
          <ReferenceManyField label={translate('ra.site._a')} reference="client/sites" target="company_id">
            <List 
              style={{width: '100%'}}
              pagination={false} 
              bulkActionButtons={false} 
              filterDefaultValues={{ company_id: record?.id }} 
              actions={false} 
              exporter={false} 
              aside={<SiteRecipientAside record={record} />}
            >
              <Datagrid rowClick="show">
                <TextField source="name" label={translate('ra.site.name')} />
                <TextField source="address" label={translate('ra.site.address')}/>
                <TextField source="contact" label={translate('ra.site.contact')}/>
                <TextField source="business_hour" label={translate('ra.site.business_hour')}/>
                <TextField source="districtName" label={translate('ra.site.district')}/>
                <EditButton label={translate('ra.action.edit')}/>
              </Datagrid>
            </List>
          </ReferenceManyField>
        </Tab>
        <Tab label={translate('ra.serviceItem.title')} path="service-items">
          <ReferenceManyField
            label={translate('ra.serviceItem._a')}
            reference="client/company-service-items"
            target="company_id"
            sort={{ field: "itemCat", order: "DESC" }}
          >
            <List
              filters={<CompanyShowCompanyServiceItemFilter />}
              filterDefaultValues={{ company_id: record?.id }}
              actions={<CompanyServiceItemListActions />}
              hasCreate={false}
              exporter={false}
              bulkActionButtons={false}
            >
              <Datagrid>
                <FunctionField source="item.category.name" label={translate('ra.serviceItem.cat')} render={record => {
                  return translate(`ra.order.category.${record.item.category.code}`);
                }} />
                <TextField source={locale==='zh-TW'? "item.subCategory.name" : "item.subCategory.name_en"} label={translate('ra.serviceItem.subCat')} />
                <ReferenceField
                  source="item_id"
                  reference="code/service-items"
                  label={translate('ra.serviceItem.name')}
                ><TextField source={locale==='zh-TW'? "name" : "name_en"} />
                </ReferenceField>
                <TextField source="charge_price" label={translate('ra.serviceItem.charge')} />
                <FunctionField label={translate('ra.serviceItem.type')} render={
                  record => {
                    return (
                      <>
                        {
                          record.type === 1 ?
                          translate('ra.serviceItem.type_default')
                            :
                            translate('ra.serviceItem.type_nro')
                        }
                      </>
                    )
                  }}
                />
                {/*<FunctionField label={translate('ra.serviceItem.created_at')}
                  render={record => {
                    const date = new Date(`${record.created_at}`);
                    return (
                      <div>
                        {convertLocaleDate(date, locale)}
                      </div>
                    )
                  }}
                />
                <FunctionField label={translate('ra.serviceItem.updated_at')}
                  render={record => {
                    const date = new Date(`${record.updated_at}`);
                    return (
                      <div>
                        {convertLocaleDate(date, locale)}
                      </div>
                    )
                  }}
                />*/}
              </Datagrid>
            </List>
          </ReferenceManyField>
        </Tab>
        {/* <Tab label="分店帳戶資料">
          <ReferenceManyField
            label="分店帳戶"
            reference="client/site-users"
            target="company_id"
          >
            <Datagrid>
              <TextField source="username" label="帳戶名稱" />
              <TextField source="full_name" label="用戶名稱" />
              <TextField source="status" label="Status" />
              <TextField source="email" label="Email" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab> */}
      </TabbedShowLayout>
    </Show >
  )
};

export const CompanyCreate = (props) => (
  <Create {...props}>
    <CompanyForm />
  </Create>
);

export const CompanyEdit = (props) => (
  <Edit {...props} undoable={false} >
    <CompanyForm toolbar={<CompanyEditToolbar />} />
  </Edit>
);