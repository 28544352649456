// in src/authProvider.js
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS, usePermissions } from 'react-admin';
import Config from 'Config';
import myAxios from 'helpers/axios';

export default (type, params) => {
    if (type === AUTH_GET_PERMISSIONS) {
        return myAxios.get(myAxios.addApiUrl('/client/account/me')).then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            const {is_verified} = response.data;
            return Promise.resolve({is_verified, role: 'user'});
        });
    }
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(Config.apiHost + '/client/account/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ username, auth_key,company_id, sites, id, full_name }) => {
                localStorage.setItem('user_id', id);
                localStorage.setItem('auth_user', username);
                localStorage.setItem('full_name', full_name)
                localStorage.setItem('token', auth_key);
                localStorage.setItem('company_id',company_id);
                localStorage.setItem('site_ids', sites);
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('auth_user');
        localStorage.removeItem('company_id');
        localStorage.removeItem('site_ids');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }
    if (type === 'FORGET_PASSWORD') {
        return Promise.resolve();
    }
    return Promise.resolve();
}