import {
    FunctionField,
    useRecordContext
} from 'react-admin';
import PropTypes from 'prop-types';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';

const BooleanNumField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    return (
        <>  
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-shrink">
                <span>{props.label}</span>
            </label>
            {
                record[source] == 1 ? 
                <Done />
                : 
                <Clear />
            }
        </>
    )
}

BooleanNumField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default BooleanNumField;