import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';

const BasicTable = ({
    columns = [],
    data = [],
    renderRow = () =>{},
    spanningTable,
    columnProps = {},
    tableProps = {},
    hasTranslation,
    translatePath = '',
}) => {
    const translate = useTranslate();
    return (
    <TableContainer component={Paper} style={{marginTop: 2, marginBottom: '16px'}}>
      <Table sx={{ minWidth: 650, my: 4 }} aria-label="simple table" {...tableProps}>
        <TableHead>
          <TableRow>
            {
                columns.map((column, i) => {
                    const aligment = i === columns.length-1 ? 'right' : 'left';  
                    return (
                        <TableCell align={aligment} columnProps><Typography style={{fontWeight: 700}}>{hasTranslation ? translate(`${translatePath}.${column}`) : column}</Typography></TableCell>
                    )
                })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            renderRow(row)
          ))}
          {spanningTable}
        </TableBody>
      </Table>
    </TableContainer>
    )
}

export default BasicTable;